<template>
  <div class="my-10">
    <v-dialog v-model="dialogPagamentoNaoProcessado" width="500">
      <v-card>
        <v-card-text class="pt-8">
          Ainda não conseguimos processar o seu pagamento. Por favor, aguarde
          alguns segundos e tente novamente.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="mt-n3">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialogPagamentoNaoProcessado = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div v-if="base64_qrCode" class="my-10">
      <v-img :src="qrCode" class="m-auto qr-code"></v-img>
    </div>
    <div class="mt-4">
      <v-text-field
        class="qr-code m-auto"
        readonly
        outlined
        disabled
        :value="pixCopiaCola"
        id="pixCopiaCola"
        append-icon
      >
        <template v-slot:append="">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                @click="copiarChavePix"
                style="cursor: pointer"
                class="ml-2"
                v-on="on"
              >
                mdi-content-copy
              </v-icon>
            </template>
            <span>Copiar Chave</span>
          </v-tooltip>
        </template>
      </v-text-field>
    </div>
    <p>
      Efetue a leitura do QR Code ou copie o código acima para concluir o
      pagamento via Pix.
    </p>
    <p @click="consultarStatusPix" class="link-dashboard">
      Já concluiu a transação? Acesse o seu Dashboard aqui para conferir o
      status.
    </p>
  </div>
</template>
<script>
import PagamentoService from "../../services/pagamento_service";
import vuex_snackbar_mixin from "@/mixins/vuex_snackbar_mixin.js";

export default {
  mixins: [vuex_snackbar_mixin],
  props: {
    txid: {
      type: String,
      require: true,
    },
    pixCopiaCola: {
      type: String,
      require: true,
    },
  },
  data: () => ({
    base64_qrCode: "",
    services: {
      pagamentoService: new PagamentoService(),
    },
    no_pix: false,
    dialogPagamentoNaoProcessado: false,
  }),
  beforeMount() {
    this.gerarQrCodePix();
  },
  computed: {
    qrCode() {
      return `data:image/png;base64,${this.base64_qrCode}`;
    },
  },
  methods: {
    gerarQrCodePix() {
      this.services.pagamentoService.consultarQrCode(
        {
          onSucess: (status) => (resp) => {
            if (status == 200) {
              console.log(resp);
              this.base64_qrCode = resp.body.data.imagem_base64;
            }
          },

          onEnd: () => {
            if (!this.base64_qrCode) this.no_pix = true;
          },

          onError: (e) => {
            console.log(e);
          },
        },
        { txid: this.txid }
      );
    },
    copiarChavePix() {
      let textoCopiado = document.getElementById("pixCopiaCola");
      textoCopiado.select();
      textoCopiado.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(textoCopiado.value);

      this.$_ACTIONS_showSnackbarMessage({
        message: "Chave copiada!",
        color: "sucess",
      });
    },
    async consultarStatusPix() {
      //TODO dashboard-cliente
      const pix = await this.services.pagamentoService.consultarStatusPix(
        this.txid
      );

      if (pix.Status === "ATIVA") {
        this.dialogPagamentoNaoProcessado = true;
      } else if (pix.Status === "PAGO") {
        this.$router.push("dashboard-cliente");
      } else {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Chave PIX Expirada",
          color: "error",
        });
      }
    },
  },
};
</script>

<style>
.qr-code {
  width: 350px;
}
.link-dashboard {
  color: #1daf80;
  cursor: pointer;
}
@media (max-width: 600px) {
  .qr-code {
    width: 60%;
  }
}
</style>
