<template>
  <div>
    <div class="p-lg-3">
      <div class="row justify-content-between">
        <h3 class="mt-0 mb-2 font-weight-bold text-left">Teleorientação</h3>
      </div>
    </div>
    <dashboard-medico />
  </div>
</template>

<script>
import VuexSnackbarMixin from "@/mixins/vuex_snackbar_mixin.js";
// import Filtro from "@/components/agendamento_medico/filtro.vue";
// import endpoints from "../router/endpoints";
import DashboardMedico from "@/components/dashboard_medico_televet/index.vue";

export default {
  mixins: [VuexSnackbarMixin],
  components: { DashboardMedico },
  data: () => ({}),
  async mounted() {},
};
</script>

<style scoped>
.btn-app-primary {
  background-color: rgb(19, 125, 91) !important;
  border-color: rgb(19, 125, 91);
  color: white;
}
.form-group label {
  text-align: left !important;
  display: block;
}
</style>
