<template>
  <div>
    <v-btn
      class="mx-2 "
      fab
      dark
      small
      color="green"
      fixed
      right="15px"
      bottom="15px"
      @click="whatsapp()"
    >
      <v-icon dark>mdi-whatsapp</v-icon>
    </v-btn>
    <div class="p-lg-3" v-if="!visualizarTelevet">
      <div class="row">
        <div class="layout-mob">
          <h3 class="mt-0 mb-0">
            Bem vindo!
          </h3>
          <h4 class="capitalize">
            {{ $_GETTERS_usuario.dados.nome.toLowerCase() }}
          </h4>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center" v-else>
      <div class="close-televet">
        <v-btn @click="visualizarTelevet = false" elevation="2" icon>
          <v-icon dark>
            fas fa-chevron-left
          </v-icon>
        </v-btn>
      </div>
      <div class="title-televet"><h3>Consulta Teleorientação</h3></div>
    </div>
    <v-divider></v-divider>
    <div v-if="!petCadastrado">
      <v-dialog
        persistent
        fullscreen
        v-model="dialogCadastrar"
        max-width="500px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="#1daf80" dark class="mb-2" v-bind="attrs" v-on="on">
            Cadastre seu primeiro Pet
          </v-btn>
        </template>
        <modal-cadastro
          v-if="dialogCadastrar"
          @close="dialogCadastrar = false"
          @response="verificarPet"
        />
      </v-dialog>
    </div>
    <div class="row justify-content-between my-6" v-else>
      <v-dialog
        persistent
        fullscreen
        v-model="dialogAgendarTelevet"
        max-width="500px"
        v-if="false"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark outlined color="#1daf80" v-bind="attrs" v-on="on">
            Nova Teleorientação
          </v-btn>
        </template>
        <v-card v-if="dialogAgendarTelevet">
          <v-toolbar style="background-color: #1daf80; color: #fff">
            <v-btn icon dark @click="dialogAgendarTelevet = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>
              <span class="headline">Nova Consulta Televet Play</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items> </v-toolbar-items>
          </v-toolbar>
          <agendar-televet
            v-if="dialogAgendarTelevet"
            @close="dialogAgendarTelevet = false"
            @response="agendamentoCadastrado"
          />
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogConsulta" max-width="500px">
        <modal-consulta
          v-if="dialogConsulta"
          :infos="teleconsultaInfo"
          @close="dialogConsulta = false"
        />
      </v-dialog>
      <v-btn
        dark
        color="#1daf80"
        @click="
          visualizarTelevet = true;
          getFilaCliente();
        "
        class="btn-atualizar"
      >
        {{ visualizarTelevet ? "Atualizar" : "Visualizar Teleorientação" }}
      </v-btn>
    </div>
    <div v-if="visualizarTelevet">
      <div v-if="listaTeleconsultas.length > 0" class="cards-style">
        <v-template
          v-for="teleconsulta in listaTeleconsultas"
          :key="teleconsulta.id_requisicao_procedimento"
        >
          <v-card elevation="2" class="mx-auto" width="100%">
            <div class="style-card-teleconsulta mb-2 d-none d-sm-flex">
              <div class="text-left" :title="teleconsulta.nome_pet">
                <font class="font-weight-bold">Pet:</font>
                {{ limitarText(teleconsulta.nome_pet) }}
              </div>
              <div class="text-left">
                <font class="font-weight-bold">Raça:</font>
                {{ limitarText(teleconsulta.nome_raca) }}
              </div>
              <div class="text-left">
                <font class="font-weight-bold">Data Consulta:</font>
                {{
                  formatarData(
                    teleconsulta.agendamento_data,
                    teleconsulta.agendamento_hora
                  )
                }}
              </div>
              <div class="text-right">
                <v-btn
                  v-if="teleconsulta.status_sala == '1'"
                  dark
                  color="#2C7091"
                  small
                  width="139px"
                  @click="getSalaConferencia(teleconsulta)"
                >
                  Inciar Consulta
                </v-btn>
                <v-btn v-else disabled color="#f0a211" width="139px" small>
                  Aguardando
                </v-btn>
              </div>
            </div>
            <div class="style-card-teleconsulta-mob d-flex d-sm-none">
              <div>
                <div class="text-left" :title="teleconsulta.nome_pet">
                  <font class="font-weight-bold">Pet:</font>
                  {{ limitarText(teleconsulta.nome_pet) }}
                </div>
                <div class="text-left">
                  <font class="font-weight-bold">Raça:</font>
                  {{ limitarText(teleconsulta.nome_raca) }}
                </div>
                <div class="text-left">
                  <font class="font-weight-bold">Data Consulta:</font>
                  {{
                    formatarData(
                      teleconsulta.agendamento_data,
                      teleconsulta.agendamento_hora
                    )
                  }}
                </div>
              </div>
              <div>
                <div class="text-right">
                  <v-btn
                    class="btn-mob-atendimento"
                    v-if="teleconsulta.status_sala == '1'"
                    dark
                    color="#2C7091"
                    small
                    width="120px"
                    @click="getSalaConferencia(teleconsulta)"
                  >
                    Inciar Consulta
                  </v-btn>
                  <v-btn
                    v-else
                    disabled
                    color="#f0a211"
                    width="139px"
                    small
                    class="btn-mob-atendimento"
                  >
                    Aguardando
                  </v-btn>
                </div>
              </div>
            </div>
            <div class="style-card-tempo" v-if="false">
              <!-- <div class="style-card-tempo" v-if="teleconsulta.id_medico == null"> -->
              Olá! Estamos agilizando a consulta online do seu pet.
              {{
                tempoEstimado(
                  teleconsulta.data_criacao,
                  teleconsulta.hora_criacao
                )
              }}
            </div>
          </v-card>
        </v-template>
      </div>
      <div v-if="listaTeleconsultas.length == 0">
        <h3>
          Sem teleconsultas
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
import VuexMixin from "@/mixins/vuex_mixin";
import FilaClienteService from "../services/fila_cliente_service.js";
import AgendamentoSalaService from "../services/agendamento_sala_service.js";
import PetsService from "../services/pets_service.js";
import AgendarTelevet from "@/components/televet/modal_cadastro_cliente.vue";
import modalCadastro from "@/components/pets/modal_cadastro";
import modalConsulta from "@/components/dashboard_cliente/modal_consulta.vue";
import K2Service from "../services/k2web_service.js";

export default {
  mixins: [VuexMixin],
  components: {
    AgendarTelevet,
    modalCadastro,
    modalConsulta,
  },
  data: () => ({
    teleconsultaInfo: {},
    dialogConsulta: false,
    listaTeleconsultas: [],
    dialogCadastrar: false,
    petCadastrado: false,
    dialogAgendarTelevet: false,
    dialogTeste: false,
    visualizarTelevet: false,
    segundosRequisicao: 1,
    service: {
      filaClienteService: FilaClienteService.build(),
      agendamentoSalaService: AgendamentoSalaService.build(),
      k2Service: K2Service.build(),
      petService: PetsService.build(),
    },
  }),
  mounted() {
    this.verificarPet(true);
    this.loopFilaCliente();
    setTimeout(() => {
      this.getFilaCliente();
    }, this.segundosRequisicao * 1000);
  },
  destroyed() {
    this.visualizarTelevet = false;
  },
  methods: {
    teste() {
      this.service.k2Service.postSendMensagem({
        type: "send",
        number: "+5585981795359",
        msg: "Seu agendamento Teleorientação foi confirmada!",
        format: "padrao",
      });
    },
    loopFilaCliente() {
      const perfilId = JSON.parse(sessionStorage.vuex).perfil.id;
      if (perfilId != undefined) {
        this.getFilaCliente();
        setTimeout(() => {
          if (perfilId == 4) {
            this.loopFilaCliente();
          }
        }, this.segundosRequisicao * 1000);
      } else {
        this.loopFilaCliente();
      }
    },
    getClienteId() {
      let clienteId = JSON.parse(sessionStorage.vuex).usuario.dados.clienteId;
      if (clienteId != undefined) {
        return clienteId;
      } else {
        this.getClienteId();
      }
    },
    async verificarPet(primeiraVez = false) {
      await this.service.petService
        .getPetsByCliente()
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          const { pets } = data;
          this.petCadastrado = pets.length > 0 ? true : false;
        })
        .finally(() => {
          if (primeiraVez && this.petCadastrado == false) {
            this.dialogCadastrar = true;
          }
        });
    },
    async getFilaCliente() {
      this.listaConsultas = [];
      const clienteId = this.getClienteId();
      if (this.visualizarTelevet == true) {
        await this.service.filaClienteService
          .getListByCliente(clienteId, {
            page: "1",
            per_page: "50",
            sort: "",
            desc: "",
          })
          .then(async (response) => {
            if (response.status !== 200) return;
            if (response.status == 206) {
              this.listaTeleconsultas = [];
              return;
            }
            const { data } = await response.json();
            if (this.listaTeleconsultas != data.filas) {
              this.listaTeleconsultas = data.filas;
            }
          })
          .catch((e) => {
            console.error(e);
          })
          .finally(() => {});
      }
    },
    getSalaConferencia(teleconsulta) {
      this.teleconsultaInfo = teleconsulta;
      this.dialogConsulta = true;
    },
    // async getSalaConferencia(idRequisicaoProcedimento) {
    //   await this.service.agendamentoSalaService
    //     .getSalaAgendamento(idRequisicaoProcedimento)
    //     .then(async (response) => {
    //       if (response.status !== 200) return;
    //       const { data } = await response.json();
    //       const url = data.urlmeeting;
    //       this.urlteste = url;
    //       window.open("https://www.uol.com.br", "_blank");
    //     })
    //     .catch((e) => {
    //       console.error(e);
    //     })
    //     .finally(() => {});
    // },
    formatarData(data, hora) {
      let arrData = data.split("-");
      let arrHora = hora.split(":");
      return `${arrData[2]}/${arrData[1]}/${arrData[0]} ${arrHora[0]}:${arrHora[1]}`;
    },
    agendamentoCadastrado() {
      this.dialogAgendarTelevet = false;
      this.visualizarTelevet = true;
      this.getFilaCliente();
    },
    whatsapp() {
      window.open(
        "https://api.whatsapp.com/send?phone=5511999858147&text=Ol%C3%A1,%20preciso%20de%20ajuda",
        "_blank"
      );
    },
    tempoEstimado(dataConsulta, horaConsulta) {
      let dateNow = new Date();
      let dateConsulta = new Date(`${dataConsulta}T${horaConsulta}`);
      let diffTime = 15 - (dateNow.valueOf() - dateConsulta.valueOf()) / 60000;
      let mensagem = "Aguarde você será atendido em breve!";
      if (parseInt(diffTime) > 0) {
        mensagem = ` O tempo médio de espera é de até ${parseInt(
          diffTime
        )} minutos. Por favor,
            aguarde :)`;
      }
      return mensagem;
    },
    limitarText(text, caracteres = 15) {
      if (text.length > caracteres) {
        return text.slice(0, caracteres) + "...";
      } else {
        return text;
      }
    },
  },
};
</script>
<style scoped>
.capitalize {
  text-transform: capitalize;
}
.cards-style {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.style-card-tempo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-weight: bold;
}
.style-card-teleconsulta-mob,
.style-card-teleconsulta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.style-card-teleconsulta div {
  width: 25%;
}
.layout-mob {
  display: flex;
  flex-direction: column;
}
.layout-mob h3,
.layout-mob h4 {
  color: #444444;
  text-align: start;
}
.btn-atualizar {
  margin-left: 10px;
  border-radius: 20px;
}
.close-televet {
  margin-right: 15px;
}
.title-televet {
}
@media (max-width: 575.98px) {
  .layout-mob {
    width: 100%;
  }
  .layout-mob h3,
  .layout-mob h4 {
    text-align: center;
  }
  .layout-mob h4 {
    font-size: 1rem;
  }
  .layout-mob h3 {
    font-size: 1.5rem;
  }
  .btn-atualizar {
    font-size: 0.75rem;
    margin: auto;
  }
  .close-televet {
    margin-right: 0px;
    position: absolute;
  }
  .title-televet {
    margin: auto;
  }
  .btn-mob-atendimento {
    font-size: 0.7rem;
  }
}
</style>
