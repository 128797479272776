<template>
  <div class="text-left py-4 px-5">
    <h4 class="text-center">
      Termos de Responsabilidade Termo de Aceite DESK VET.
    </h4>
    <p>
      O uso da teleorientação no Video Front oferecidos pela DESK VET está
      condicionado à aceitação e ao cumprimento deste Termo de Aceite pelas
      pessoas físicas que o acessem.
    </p>
    <p>
      Ao clicar no botão "CADASTRAR", declaro estar de acordo em realizar uma
      teleorientação com um médico veterinário da DESK VET.
    </p>
    <p>
      Para isso, DECLARO estar ciente de que as informações resultantes desta
      teleorientação serão registradas no prontuário do meu pet, sendo garantido
      a confidencialidade e o sigilo entre profissional e tutor.
    </p>
    <p>
      Ainda, AUTORIZO que a equipe DESK VET tenha acesso TOTAL aos meus dados e
      aos dados do meu pet, bem como histórico de atendimento cadastrados no
      sistema PetTree, com a finalidade de obter todos os dados necessários para
      garantir o melhor atendimento veterinário ao meu pet.
    </p>
    <p>
      Por fim, PERMITO o acesso à câmera e ao microfone do meu computador para
      participar da teleorientação, bem como o uso da minha imagem e voz para
      qualquer fim que seja necessário.
    </p>
  </div>
</template>

<script>
export default {
  data: () => ({}),

  methods: {},
};
</script>
