<template>
  <div>
    <div class="p-lg-3">
      <div class="row justify-content-between">
        <h3 class="mt-0 mb-2 font-weight-bold text-left">
          Histórico Compras
        </h3>
      </div>
    </div>
    <v-form ref="form" v-model="form" lazy-validation>
      <div class="row justify-content-center">
        <div class="col-lg-4 form-group clearfix mb-3">
          <v-select
            style="padding-top: 0 !important"
            :items="lojasList"
            label="Loja"
            item-text="desc"
            item-value="id"
            v-model="lojaSelected"
          />
        </div>
        <div class="col-lg-4 form-group clearfix mb-3">
          <v-select
            style="padding-top: 0 !important"
            :items="statusList"
            label="Status"
            item-text="desc"
            item-value="id"
            v-model="statusSelected"
          />
        </div>
        <div class="col-lg-2 form-group clearfix">
          <button
            :disabled="!formValido"
            @click.prevent="getHistorico"
            class="btn btn-app-primary btn-rounded font-weight-bold"
          >
            <span v-if="!loading"> Pesquisar </span>
            <v-progress-circular
              v-else
              indeterminate
              color="white"
            ></v-progress-circular>
          </button>
        </div>
      </div>
    </v-form>

    <div class="row" v-if="pesquisado">
      <div class="col-12">
        <v-data-table
          :headers="headers"
          :items="historicoComprasLista"
          class="elevation-1"
          :loading="loading"
          v-on:update:sort-by="options.ordenacao = false"
          :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
          :loading-text="'Carregando...'"
        >
          <template v-slot:item.actions="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  title="Pets"
                  small
                  class="mr-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  fas fa-dog
                </v-icon>
              </template>
              <v-list>
                <v-list-item v-for="(pet, index) in item.pets" :key="index">
                  <v-list-item-title
                    >ID: {{ pet.id_pet }} - Nome:
                    {{ pet.nome }}</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
            <v-icon
              title="Visualizar Compra"
              small
              class="mr-2"
              @click="detalheCompra(item.id, item)"
            >
              fas fa-eye
            </v-icon>
          </template>
          <template v-slot:no-data>
            <p class="text-center">Nenhum registro encontrado</p>
            <v-btn color="#1daf80" dark @click="getHistorico">
              Atualizar
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </div>

    <!-- Dialog Detalhe da Venda -->
    <v-dialog
      v-model="dialogDetalhe"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar style="background-color: #1daf80; color: #fff">
          <v-btn icon dark @click="dialogDetalhe = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <span class="headline">Detalhe Compra</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items> </v-toolbar-items>
        </v-toolbar>
        <detalhe-compra
          v-if="dialogDetalhe"
          :compra="compra"
          :detalhe="detalhe"
          @close="dialogDetalhe = false"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import LojaService from "../services/loja_service";
import HistoricoComprasService from "../services/historico_compras_service";
import DetalheCompra from "@/components/historico_compras/detalhe_compra.vue";

export default {
  mixins: [Mixin],
  components: {
    DetalheCompra,
  },
  data: () => ({
    loading: false,
    pesquisado: false,
    formValido: false,
    search: "",
    totalList: 10,
    lojasList: [],
    lojaSelected: "",
    statusList: [
      { id: 0, desc: "Todos" },
      { id: 1, desc: "Criado" },
      { id: 2, desc: "Enviado para Pagamento" },
      { id: 3, desc: "Confirmado" },
      { id: 4, desc: "Aguardando Confirmação" },
      { id: 5, desc: "Pagamento Negado" },
    ],
    statusSelected: 0,
    historicoComprasLista: [],
    options: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      ordenacao: false,
      desc: false,
    },
    compra: "",
    detalhe: "",
    dialogDetalhe: false,
    headers: [
      { text: "ID Tutor", value: "id_tutor", sortable: false },
      { text: "Nome", value: "nome_comprador", sortable: false },
      { text: "Status", value: "desc_status_compra", sortable: false },
      { text: "Unidade", value: "unidade", sortable: false },
      { text: "Tipo Compra", value: "tipo_compra", sortable: false },
      { text: "Valor", value: "valor_formatado", sortable: false },
      { text: "Data", value: "data_requisicao_formatada", sortable: false },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    services: {
      historicoComprasService: HistoricoComprasService.build(),
      lojaService: LojaService.build(),
    },
  }),
  watch: {
    lojaSelected() {
      this.validarFormulario();
    },
    statusSelected() {
      this.validarFormulario();
    },
  },

  mounted() {
    this.getLojas();
  },
  methods: {
    validarFormulario() {
      if (this.lojaSelected > 0) {
        this.formValido = true;
      } else {
        this.formValido = false;
      }
    },
    getLojas() {
      this.services.lojaService
        .getLojas()
        .then(async (response) => {
          const { data } = await response.json();
          const { lojas } = data;
          for (let index = 0; index < lojas.length; index++) {
            const element = lojas[index];
            this.lojasList.push({
              id: element.id,
              desc: element.nome_fantasia,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getHistorico() {
      this.historicoComprasLista = [];
      this.loading = true;
      await this.services.historicoComprasService
        .getHistoricoCompra(this.lojaSelected, this.statusSelected)
        .then(async (response) => {
          const { data } = await response.json();
          const { produtos } = data[0];
          const { pets } = data[1];
          for (let index = 0; index < produtos.length; index++) {
            const elementCompra = produtos[index];
            let petArr = [];
            for (let index = 0; index < pets.length; index++) {
              const elementPet = pets[index];
              if (elementPet.id_compra == elementCompra.id) {
                petArr.push(elementPet);
              }
            }
            this.historicoComprasLista.push({
              id: elementCompra.id,
              id_tutor: elementCompra.id_tutor,
              nome_comprador: elementCompra.nome,
              id_status_compra: elementCompra.id_status_compra,
              desc_status_compra: elementCompra.resposta_loja_texto,
              unidade: elementCompra.unidade,
              tipo_compra: elementCompra.tipo_comra,
              valor: elementCompra.valor,
              valor_formatado: parseFloat(elementCompra.valor).toLocaleString(
                "pt-br",
                {
                  style: "currency",
                  currency: "BRL",
                }
              ),
              data_requisicao: elementCompra.data_requisicao,
              data_requisicao_formatada: this.formatarData(
                elementCompra.data_requisicao
              ),
              pets: petArr,
            });
            this.loading = false;
            this.pesquisado = true;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.pesquisado = true;
          console.log(error);
        });
    },
    detalheCompra(id_compra, item) {
      this.idCompra = id_compra;
      this.compra = item;

      this.services.historicoComprasService
        .getDetalheCompra(this.idCompra)
        .then(async (response) => {
          const { data } = await response.json();
          const { produtos } = data[0];
          const { pets } = data[1];
          this.detalhe = { produtos: produtos, pets: pets };
          this.dialogDetalhe = true;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    formatarData(data_requisicao) {
      if (data_requisicao) {
        const arrData = data_requisicao.split("-");
        return `${arrData[2]}/${arrData[1]}/${arrData[0]}`;
      } else {
        return "Sem Data";
      }
    },
  },
};
</script>
<style>
.btn-ativo {
  color: #1a8c1a !important;
}
.btn-inativo {
  color: #ae1111 !important;
  transform: rotate(180deg);
}
</style>
