import HTTPService from "@/services/http_service.js";

export default class PagamentoService extends HTTPService {
  resource = "loja/compra/";
  resourcePagSeg = "https://sandbox.api.pagseguro.com";

  static build() {
    return new PagamentoService();
  }
  /**
        @returns {Promise<Response>}
     */

  postPagamento(responseFunctions, model) {
    this.jsonRequest(
      responseFunctions,
      "POST",
      `${this.resource}pagsegurocartao/new`,
      model
    );
  }

  postPagamentoPix(responseFunctions, model) {
    this.jsonRequest(
      responseFunctions,
      "POST",
      `${this.resource}fixpay/pix/new`,
      model
    );
  }

  consultarQrCode({ onSucess, onError, onEnd }, txid) {
    const responseFunctions = {
      onSucess,
      onError,
      onEnd,
    };
    this.jsonRequest(responseFunctions, "POST", "fixpay/pix/qrcode", txid);
  }

  async consultarStatusPix(txid) {
    const response = await this.get("fixpay/pix/status", { txid });
    return response.json();
  }

  getPublicKey() {
    return this.get(`pagseguro/key`);
  }
}
