<template>
  <div>
    <div class="row justify-content-center mt-5 p-5">
      <v-form ref="form" class="col-10" v-model="form" lazy-validation>
        <div class="row align-items-end">
          <div class="col-lg-5 form-group clearfix mb-3">
            <label><strong>Nome Produto</strong></label>
            <v-text-field
              v-model="nomeProd"
              :type="'text'"
              :rules="[rules.required]"
              placeholder="Nome Produto"
              style="padding-top: 0 !important"
            ></v-text-field>
          </div>
          <div class="col-lg-5 form-group clearfix mb-3">
            <label><strong>Descrição Produto</strong></label>
            <v-text-field
              v-model="descProd"
              :type="'text'"
              :rules="[rules.required]"
              placeholder="Descrição Produto"
              style="padding-top: 0 !important"
            ></v-text-field>
          </div>
          <div class="col-lg-2 form-group clearfix mb-3 text-end">
            <label><strong>Status Produto</strong></label>
            <v-switch
              v-model="statusProd"
              :label="`${statusProd ? 'Ativa' : 'Inativa'}`"
              style="padding-top: 0 !important"
            ></v-switch>
          </div>
        </div>
        <div class="row align-items-end">
          <div class="col-lg-5 form-group clearfix mb-3">
            <label><strong>Unidade</strong></label>
            <v-autocomplete
              :items="listaUnidades"
              item-text="desc"
              item-value="id"
              :rules="[rules.required]"
              v-model="unidade"
              placeholder="Unidade"
            ></v-autocomplete>
          </div>
          <div class="col-lg-4 form-group clearfix mb-3">
            <label><strong>Tipo Produto</strong></label>
            <v-autocomplete
              :items="listaTipoServ"
              item-text="desc"
              item-value="id"
              :rules="[rules.required]"
              v-model="tipoServ"
              placeholder="Tipo Produto"
            ></v-autocomplete>
          </div>
          <div class="col-lg-2 form-group clearfix mb-3">
            <label><strong>Valor Produto</strong></label>
            <v-text-field
              type="number"
              style="padding-top: 0 !important"
              :rules="[rules.required]"
              v-model="valor"
              placeholder="Valor Produto"
            ></v-text-field>
          </div>
        </div>
        <div class="row align-items-end">
          <div class="col-lg-2 form-group clearfix mb-3 text-end">
            <label><strong>Gera Voucher</strong></label>
            <v-switch
              v-model="geraVoucher"
              :label="`${geraVoucher ? 'Sim' : 'Não'}`"
              style="padding-top: 0 !important"
            ></v-switch>
          </div>
          <div class="col-lg-2 form-group clearfix mb-3 text-end">
            <label><strong>Recorrente</strong></label>
            <v-switch
              v-model="recorrencia"
              :label="`${recorrencia ? 'Sim' : 'Não'}`"
              style="padding-top: 0 !important"
              :disabled="geraVoucher ? true : true"
            ></v-switch>
          </div>
        </div>

        <div class="col-md-12 text-right">
          <button
            @click.prevent="cadastrarProduto"
            class="btn btn-app-primary btn-rounded font-weight-bold"
          >
            <span v-if="!loading"> Cadastrar </span>
            <v-progress-circular
              v-else
              indeterminate
              color="white"
            ></v-progress-circular>
          </button>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import CEPMixin from "@/mixins/cep_mixin.js";
import Mixin from "@/mixins/vuex_mixin.js";
import UnidadeService from "../../services/unidade_service";
import ProdutosServicosService from "../../services/produtos_servicos_service";
import AuthService from "../../services/auth_service";
import ValidationService from "../../services/validation_service";

export default {
  mixins: [CEPMixin, Mixin],
  data() {
    return {
      form: false,
      loading: false,
      statusProd: false,
      geraVoucher: false,
      recorrencia: false,
      nomeProd: "",
      descProd: "",
      valor: "",
      listaUnidades: [],
      unidade: "",
      listaTipoServ: [
        { id: 1, desc: "Produto" },
        { id: 2, desc: "Serviço" },
      ],
      tipoServ: "",
      services: {
        unidadeService: UnidadeService.build(),
        produtosServicosService: ProdutosServicosService.build(),
        authService: AuthService.build(),
        validationService: ValidationService,
      },
      rules: {
        required: (value) => !!value || "Campo obrigatório.",
      },
    };
  },
  mounted() {
    this.listarUnidades();
    // this.listarProdutosServicos();
  },
  methods: {
    async listarUnidades() {
      this.listaUnidades = [];
      await this.services.unidadeService
        .getUnidadeList()
        .then((response) => {
          this.listaUnidades = response.map((u) => ({
            id: u.unidadeId,
            desc: u.nomeFantasia,
          }));
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async listarProdutosServicos() {
      this.listaProdServ = [];
      await this.services.produtosServicosService
        .getProdutosServicos()
        .then((response) => {
          this.listaProdServ = response.map((p) => ({
            id: p.id,
            desc: p.desc,
          }));
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cadastrarProduto() {
      this.loading = true;
      this.services.produtosServicosService.postCreateProdutosServicos(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.$_ACTIONS_showSnackbarMessage({
                message:
                  body.message || this.$global.messages.internalServerError,
                color: "sucess",
              });
              this.zerarFormulario();
              this.$emit("response");
              this.$emit("close");
            } else {
              this.$_ACTIONS_showSnackbarMessage({
                message:
                  body.message || this.$global.messages.internalServerError,
                color: "error",
              });
            }
          },
          onError: (message) => {
            this.$_ACTIONS_showSnackbarMessage({
              message: message || this.$global.messages.internalServerError,
              color: "error",
            });
            this.$emit("remarcar", { message });
          },
          onEnd: () => {
            this.loading = false;
          },
        },
        {
          id_unidade: parseInt(this.unidade),
          nome: this.nomeProd,
          descricao: this.descProd,
          id_tipo: this.tipoServ,
          id_status: this.statusProd ? 1 : 2,
          valor: parseFloat(this.valor),
          voucher: this.geraVoucher ? 1 : 0,
          id_tipo_voucher: 1,
          // recorrencia: this.recorrencia,
        }
      );
    },
    zerarFormulario() {
      this.nomeProd = "";
      this.descProd = "";
      this.tipoServ = "";
      this.statusLoja = false;
      this.valor = "";
      this.unidade = "";
    },
  },
};
</script>

<style scoped>
.form-group label {
  text-align: left !important;
  display: block;
}

.form-group .label-genero {
  margin-right: 1rem;
}
</style>
