<template>
  <v-card>
    <v-toolbar style="background-color: #1daf80; color: #fff">
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span class="headline">Pagamento Teleorientação</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items> </v-toolbar-items>
    </v-toolbar>
    <div class="p-5">
      <form class="p-lg-3 row">
        <v-col cols="9">
          <v-row>
            <h2 class="col-12">Verifique as informações antes de finalizar</h2>
            <div class="col-lg-6 form-group clearfix mb-3">
              <label for="nome">CPF</label>
              <v-text-field
                v-model="clienteCpf"
                v-mask="'###.###.###-##'"
                placeholder="CPF"
                style="padding-top: 0 !important"
              ></v-text-field>
            </div>
            <div class="col-lg-6 form-group clearfix mb-3">
              <label>Método de Pagamento</label>
              <v-select
                style="padding-top: 0 !important"
                :items="formasPagamentos"
                v-model="formaPagamentoId"
              />
            </div>
          </v-row>
          <v-row v-if="formaPagamentoId == 1">
            <h2 class="col-12 text-start">Crédito</h2>
            <div class="col-lg-12 form-group clearfix mb-3">
              <label for="nome">Nome do Titular</label>
              <v-text-field
                placeholder="Nome do Titular"
                style="padding-top: 0 !important"
              ></v-text-field>
            </div>
            <div class="col-lg-12 form-group clearfix mb-3">
              <label for="nome">Número do Cartão</label>
              <v-text-field
                v-model="numeroCartao"
                v-mask="'####-####-####-####'"
                placeholder="Número do Cartão"
                style="padding-top: 0 !important"
              ></v-text-field>
            </div>
            <div class="col-lg-6 form-group clearfix mb-0">
              <label>Mês de expiração</label>
              <v-text-field
                v-mask="'##'"
                placeholder="Mês de expiração"
                style="padding-top: 0 !important"
              />
            </div>
            <div class="col-lg-6 form-group clearfix mb-0">
              <label>Ano de expiração</label>
              <v-text-field
                v-mask="'####'"
                placeholder="Ano de expiração"
                style="padding-top: 0 !important"
              />
            </div>
            <div class="col-lg-4 form-group clearfix mb-0">
              <label>CVV</label>
              <v-text-field
                v-mask="'###'"
                v-model="cartaoCvv"
                placeholder="CVV"
                style="padding-top: 0 !important"
              />
            </div>
            <div class="col-lg-4 form-group clearfix mb-3">
              <label>Bandeira</label>
              <v-select
                style="padding-top: 0 !important"
                :items="bandeiras"
                v-model="bandeiraId"
              />
            </div>
            <div class="col-lg-4 form-group clearfix mb-3">
              <label>Parcelas</label>
              <v-select
                style="padding-top: 0 !important"
                :items="parcelas"
                v-model="parcelaId"
              />
            </div>
          </v-row>
        </v-col>
        <v-col cols="3">
          <v-card class="">
            <v-toolbar style="background-color: #1daf80; color: #fff">
              <v-btn icon dark @click="$emit('close')">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>
                <span class="headline">Resumo do Pagamento</span>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items> </v-toolbar-items>
            </v-toolbar>
            <div class="p-4">
              <v-row class="py-0 my-0">
                <v-col cols="12"><p>Consulta Teleorientação</p></v-col>

                <v-col cols="4" class="text-start">
                  <p><strong>TOTAL</strong></p>
                </v-col>
                <v-col>
                  <p class="text-success font-weight-black">
                    {{ valorCompraFormatado }}
                  </p>
                </v-col>
              </v-row>
              <v-row class="py-0 my-0">
                <v-col cols="12">
                  <p class="caption my-0 py-0"><strong>à vista</strong></p>
                  <p class="text-success h3 font-weight-black">
                    {{ valorCompraFormatado }}
                  </p>
                </v-col>
                <v-col cols="12">
                  <p class="caption my-0 py-0">
                    <strong>parcelado em {{ parcela.text }} de</strong>
                  </p>
                  <p class="text-success h3 font-weight-black">
                    {{ valorParcelado }}
                  </p>
                </v-col>
              </v-row>
            </div>
          </v-card>
          <v-row class="mt-2">
            <button
              class="
                btn btn-app-primary
                rounded
                col-11
                mx-auto
                font-weight-bold
              "
            >
              <span> Confirmar </span>
            </button>
          </v-row>
        </v-col>
      </form>
    </div>
  </v-card>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
// import PictureInput from "vue-picture-input";

export default {
  mixins: [Mixin],
  data: () => ({
    formaPagamentoId: 0,
    clienteCpf: "999.999.999-99",
    formasPagamentos: [
      {
        text: "Cartão de Crédito",
        value: 1,
      },
      // {
      //   text: "Cartão de Débito",
      //   value: 2,
      // },
      // {
      //   text: "Boleto",
      //   value: 3,
      // },
    ],
    bandeiraId: 1,
    bandeiras: [
      {
        text: "Visa",
        value: 1,
      },
      {
        text: "Mastercard",
        value: 2,
      },
      {
        text: "Amex",
        value: 3,
      },
      {
        text: "Diners",
        value: 4,
      },
      {
        text: "Discover",
        value: 5,
      },
    ],
    parcela: {
      text: "1x",
      value: 100,
      quantidade: 1,
    },
    parcelaId: 1,
    valorCompra: 100,
    parcelas: [
      {
        text: "1x",
        value: 1,
      },
      {
        text: "2x",
        value: 2,
      },
      {
        text: "3x",
        value: 3,
      },
      {
        text: "4x",
        value: 4,
      },
      {
        text: "5x",
        value: 5,
      },
      {
        text: "6x",
        value: 6,
      },
      {
        text: "7x",
        value: 7,
      },
      {
        text: "8x",
        value: 8,
      },
      {
        text: "9x",
        value: 9,
      },
      {
        text: "10x",
        value: 10,
      },
    ],
    numeroCartao: "",
    cartaoCvv: "",
  }),
  watch: {
    parcelaId() {
      this.parcela.quantidade = this.parcelas.find(
        (p) => p.value == this.parcelaId
      ).value;
      this.parcela.text = this.parcelas.find(
        (p) => p.value == this.parcelaId
      ).text;
    },
  },
  computed: {
    valorCompraFormatado() {
      return this.valorCompra.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    },
    valorParcelado() {
      return (this.valorCompra / this.parcela.quantidade).toLocaleString(
        "pt-br",
        {
          style: "currency",
          currency: "BRL",
        }
      );
    },
  },
  methods: {},
};
</script>
