import HTTPService from "@/services/http_service.js";
import { global } from "@/config/constants";

export default class MinhasConsultasService extends HTTPService {
  static build() {
    return new MinhasConsultasService();
  }

  async getMinhasConsultas(
    pacienteId,
    { page, per_page, search, order, desc }
  ) {
    return await this.get(`consulta/pessoal/paciente/${pacienteId}`, {
      page,
      per_page,
      search,
      order,
      desc,
    });
  }

  async getMinhasConsultasTelevet(
    pacienteId,
    { page, per_page, search, order, desc }
  ) {
    return await this.get(`consulta/televet/paciente/${pacienteId}`, {
      page,
      per_page,
      search,
      order,
      desc,
    });
  }
  async cancelarConsulta(agendamentoId) {
    const headers = new Headers();

    const token = JSON.parse(sessionStorage.getItem("vuex")).token;
    headers.append("Authorization", "Bearer " + token);

    const requestOptions = {
      method: "POST",
      headers: headers,
    };
    return await fetch(
      `${global.api.baseURL}/agendamento/${agendamentoId}/desmarcar`,
      requestOptions
    ).then(async (response) => {
      return response;
    });
  }

  getUrlMeeting(agendamentoId) {
    return this.get(`agendamento-sala/${agendamentoId}`);
  }
}
