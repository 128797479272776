<template>
  <div>
    <div class="p-lg-3">
      <div class="row justify-content-between">
        <h3 class="mt-0 mb-2 font-weight-bold text-left">
          Minhas Compras
        </h3>
      </div>
    </div>
    <v-form ref="form" v-model="form" lazy-validation>
      <div class="row justify-content-center">
        <!-- <div class="col-lg-4 form-group clearfix mb-3">
          <v-select
            style="padding-top: 0 !important"
            :items="lojasList"
            label="Loja"
            item-text="desc"
            item-value="id"
            v-model="lojaSelected"
          />
        </div> -->
        <div class="col-lg-4 form-group clearfix mb-3">
          <v-select
            style="padding-top: 0 !important"
            :items="statusList"
            label="Status"
            item-text="desc"
            item-value="id"
            v-model="statusSelected"
          />
        </div>
        <div class="col-lg-2 form-group clearfix">
          <button
            @click.prevent="getHistorico"
            class="btn btn-app-primary btn-rounded font-weight-bold"
          >
            <span v-if="!loading"> Pesquisar </span>
            <v-progress-circular
              v-else
              indeterminate
              color="white"
            ></v-progress-circular>
          </button>
        </div>
      </div>
    </v-form>

    <div class="row" v-if="pesquisado">
      <div class="col-12">
        <v-data-table
          :headers="headers"
          :items="historicoComprasLista"
          class="elevation-1"
          :loading="loading"
          v-on:update:sort-by="options.ordenacao = false"
          :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
          :loading-text="'Carregando...'"
          mobile-breakpoint="350"
        >
          <template v-slot:item.data_requisicao_formatada="{ item }">
            {{ item.data_requisicao_formatada }} - {{ item.hora }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="detalheCompra(item.id, item)"
              title="Detalhes"
            >
              {{ mobile ? "fas fa-ellipsis-v" : "fas fa-eye" }}
            </v-icon>
          </template>
          <template v-slot:no-data>
            <p class="text-center">Nenhum registro encontrado</p>
            <v-btn color="#1daf80" dark @click="getHistorico">
              Atualizar
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </div>

    <!-- Dialog Detalhe da Venda -->
    <v-dialog
      v-model="dialogDetalhe"
      :fullscreen="mobile ? false : true"
      :hide-overlay="mobile ? false : true"
      :scrollable="mobile ? true : false"
      transition="dialog-bottom-transition"
    >
      <v-card v-if="!mobile">
        <v-toolbar style="background-color: #1daf80; color: #fff">
          <v-btn icon dark @click="dialogDetalhe = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <span class="headline">Detalhe Compra</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items> </v-toolbar-items>
        </v-toolbar>
        <detalhe-compra
          v-if="dialogDetalhe"
          :compra="compra"
          :detalhe="detalhe"
          @close="dialogDetalhe = false"
        />
      </v-card>
      <div class="mobile-style" v-else>
        <detalhe-compra
          v-if="dialogDetalhe"
          :compra="compra"
          :detalhe="detalhe"
          @close="dialogDetalhe = false"
        />
      </div>
    </v-dialog>
  </div>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import LojaService from "../services/loja_service";
import HistoricoComprasService from "../services/historico_compras_service";
import DetalheCompra from "@/components/historico_compras/detalhe_compra_cliente.vue";

export default {
  mixins: [Mixin],
  components: {
    DetalheCompra,
  },
  data: () => ({
    loading: false,
    pesquisado: false,
    formValido: false,
    search: "",
    totalList: 10,
    lojasList: [],
    lojaSelected: "",
    statusList: [
      { id: 0, desc: "Todos" },
      { id: 1, desc: "Criado" },
      { id: 2, desc: "Enviado para Pagamento" },
      { id: 3, desc: "Confirmado" },
      { id: 4, desc: "Aguardando Confirmação" },
      { id: 5, desc: "Pagamento Negado" },
    ],
    statusSelected: 0,
    id_usuario: JSON.parse(sessionStorage.vuex).usuario.id,
    historicoComprasLista: [],
    options: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      ordenacao: false,
      desc: false,
    },
    compra: "",
    detalhe: "",
    dialogDetalhe: false,
    mobile: false,
    headers: [
      { text: "Status", value: "desc_status_compra", sortable: false },
      { text: "Unidade", value: "unidade", sortable: false },
      {
        text: "Tipo Compra",
        value: "tipo_compra",
        sortable: false,
      },
      { text: "Valor", value: "valor_formatado", sortable: false },
      { text: "Data", value: "data_requisicao_formatada", sortable: false },
      {
        text: "",
        align: "end",
        value: "actions",
        sortable: false,
      },
    ],
    services: {
      historicoComprasService: HistoricoComprasService.build(),
      lojaService: LojaService.build(),
    },
  }),
  watch: {
    // lojaSelected() {
    //   this.validarFormulario();
    // },
    // statusSelected() {
    //   this.validarFormulario();
    // },
  },

  mounted() {
    window.addEventListener("resize", this.verificarLarguraTela);
    this.verificarLarguraTela();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.verificarLarguraTela);
  },
  methods: {
    validarFormulario() {
      if (this.lojaSelected > 0) {
        this.formValido = true;
      } else {
        this.formValido = false;
      }
    },
    getLojas() {
      this.services.lojaService
        .getLojas()
        .then(async (response) => {
          const { data } = await response.json();
          const { lojas } = data;
          for (let index = 0; index < lojas.length; index++) {
            const element = lojas[index];
            this.lojasList.push({
              id: element.id,
              desc: element.nome_fantasia,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getHistorico() {
      this.historicoComprasLista = [];
      this.loading = true;
      await this.services.historicoComprasService
        .getHistoricoComprabyIdUsuario(this.id_usuario, this.statusSelected)
        .then(async (response) => {
          const { data } = await response.json();
          const { compras } = data;
          for (let index = 0; index < compras.length; index++) {
            const element = compras[index];
            this.historicoComprasLista.push({
              id: element.id,
              id_status_compra: element.id_status_compra,
              desc_status_compra: element.resposta_loja_texto,
              unidade: element.unidade,
              tipo_compra: element.tipo_compra,
              valor: element.valor,
              hora: element.hora_requisicao,
              valor_formatado: parseFloat(element.valor).toLocaleString(
                "pt-br",
                {
                  style: "currency",
                  currency: "BRL",
                }
              ),
              data_requisicao: element.data_requisicao,
              data_requisicao_formatada: this.formatarData(
                element.data_requisicao
              ),
            });
          }
          this.loading = false;
          this.pesquisado = true;
        })
        .catch((error) => {
          this.loading = false;
          this.pesquisado = true;
          console.log(error);
        });
    },
    detalheCompra(id_compra, item) {
      this.idCompra = id_compra;
      this.compra = item;

      this.services.historicoComprasService
        .getDetalheCompra(this.idCompra)
        .then(async (response) => {
          const { data } = await response.json();
          const { produtos } = data[0];
          const { pets } = data[1];
          this.detalhe = { produtos: produtos, pets: pets };
          this.dialogDetalhe = true;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    formatarData(data_requisicao) {
      if (data_requisicao) {
        const arrData = data_requisicao.split("-");
        return `${arrData[2]}/${arrData[1]}/${arrData[0]}`;
      } else {
        return "Sem Data";
      }
    },
    verificarLarguraTela() {
      const larguraTela = window.innerWidth;
      if (larguraTela >= 576) {
        this.mobile = false;
        if (!this.headers.some((item) => item.value == "tipo_compra")) {
          this.headers.splice(2, 0, {
            text: "Tipo Compra",
            value: "tipo_compra",
            sortable: false,
          });
        }
        const index = this.headers.findIndex((item) => item.value == "actions");
        if (this.headers[index].text == "") {
          this.headers[index].text = "Ações";
        }
      } else {
        this.mobile = true;
        if (this.headers.some((item) => item.value == "tipo_compra")) {
          this.headers.splice(2, 1);
        }
        const index = this.headers.findIndex((item) => item.value == "actions");
        if (this.headers[index].text == "Ações") {
          this.headers[index].text = "";
        }
      }
    },
  },
};
</script>
<style scoped>
.btn-ativo {
  color: #1a8c1a !important;
}
.btn-inativo {
  color: #ae1111 !important;
  transform: rotate(180deg);
}
.mobile-style {
  background-color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  max-height: 75%;
  overflow: auto;
  border-radius: 25px 25px 0px 0px;
  padding-bottom: 25px;
}
@media (max-width: 575.98px) {
}
</style>
