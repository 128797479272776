var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-toolbar',{staticStyle:{"background-color":"#1daf80","color":"#fff"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[(_vm.visualizar)?_c('span',{staticClass:"headline"},[_vm._v("Visualizar unidade")]):_c('span',{staticClass:"headline"},[_vm._v("Editar unidade")])]),_c('v-spacer'),_c('v-toolbar-items')],1),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"form",staticClass:"p-lg-3 row"},[_c('div',{staticClass:"col-lg-6 form-group clearfix mb-3"},[_c('label',{attrs:{"for":"nome"}},[_vm._v("Nome fantasia")]),_c('v-text-field',{staticStyle:{"padding-top":"0 !important"},attrs:{"error-messages":_vm.error.nome_fantasia,"disabled":_vm.visualizar,"rules":[
                _vm.validationService.required('Campo obrigatório.'),
                _vm.validationService.isNotEmpty() ],"type":'text',"placeholder":"Nome fantasia"},model:{value:(_vm.unidade.nomeFantasia),callback:function ($$v) {_vm.$set(_vm.unidade, "nomeFantasia", $$v)},expression:"unidade.nomeFantasia"}})],1),_c('div',{staticClass:"col-lg-6 form-group clearfix mb-3"},[_c('label',{attrs:{"for":"nome"}},[_vm._v("Razão social")]),_c('v-text-field',{staticStyle:{"padding-top":"0 !important"},attrs:{"error-messages":_vm.error.razaoSocial,"disabled":_vm.visualizar,"rules":[
                _vm.validationService.required(
                  'Campo Razão Social é obrigatório.'
                ),
                _vm.validationService.isNotEmpty() ],"type":'text',"placeholder":"Razão Social"},model:{value:(_vm.unidade.razaoSocial),callback:function ($$v) {_vm.$set(_vm.unidade, "razaoSocial", $$v)},expression:"unidade.razaoSocial"}})],1),_c('div',{staticClass:"col-lg-3 form-group clearfix mb-3"},[_c('label',{attrs:{"for":"Telefone01"}},[_vm._v("Telefone Celular:")]),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('(##)#####-####'),expression:"'(##)#####-####'"}],staticStyle:{"padding-top":"0 !important"},attrs:{"error-messages":_vm.error.telefone1,"disabled":_vm.visualizar,"type":'text',"rules":[
                _vm.validationService.required('Campo Telefone é obrigatório.'),
                _vm.validationService.isNotEmpty() ],"placeholder":"(00) 00000-0000"},model:{value:(_vm.unidade.telefone1),callback:function ($$v) {_vm.$set(_vm.unidade, "telefone1", $$v)},expression:"unidade.telefone1"}})],1),_c('div',{staticClass:"col-lg-3 form-group clearfix mb-3"},[_c('label',{attrs:{"for":"Telefone01"}},[_vm._v("Telefone Fixo:")]),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('(##)####-####'),expression:"'(##)####-####'"}],staticStyle:{"padding-top":"0 !important"},attrs:{"error-messages":_vm.error.telefone2,"disabled":_vm.visualizar,"type":'text',"placeholder":"(00) 00000-0000"},model:{value:(_vm.unidade.telefone2),callback:function ($$v) {_vm.$set(_vm.unidade, "telefone2", $$v)},expression:"unidade.telefone2"}})],1),_c('div',{staticClass:"col-lg-3 form-group clearfix mb-3"},[_c('label',{attrs:{"for":"CNPJ"}},[_vm._v("CNPJ")]),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##.###.###/####-##'),expression:"'##.###.###/####-##'"}],staticStyle:{"padding-top":"0 !important"},attrs:{"error-messages":_vm.error.cnpj,"disabled":_vm.visualizar,"type":'text',"rules":[
                _vm.validationService.required('Campo CNPJ é obrigatório.'),
                _vm.validationService.isNotEmpty(),
                _vm.validationService.isCNPJ() ],"placeholder":"00.000.000/0000-00"},model:{value:(_vm.unidade.cnpj),callback:function ($$v) {_vm.$set(_vm.unidade, "cnpj", $$v)},expression:"unidade.cnpj"}}),_c('span',{staticClass:"text-muted"},[_vm._v("ex: \"123.456.7890-12\"")])],1),_c('div',{staticClass:"col-lg-3 form-group clearfix mb-3"},[_c('label',{attrs:{"for":"CEP"}},[_vm._v("CEP")]),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('#####-###'),expression:"'#####-###'"}],staticStyle:{"padding-top":"0 !important"},attrs:{"error-messages":_vm.error.cep,"disabled":_vm.visualizar,"type":'text',"rules":[
                _vm.validationService.required('Campo CEP é obrigatório.') ],"placeholder":"00000-000"},on:{"blur":_vm.searchCep},model:{value:(_vm.unidade.cep),callback:function ($$v) {_vm.$set(_vm.unidade, "cep", $$v)},expression:"unidade.cep"}}),_c('span',{staticClass:"text-muted"},[_vm._v("ex: \"xxxxx-xxx\"")])],1),_c('div',{staticClass:"col-lg-3 form-group clearfix mb-3"},[_c('label',{attrs:{"for":"Rua"}},[_vm._v("Logradouro")]),_c('v-text-field',{staticStyle:{"padding-top":"0 !important"},attrs:{"error-messages":_vm.error.logradouro,"disabled":_vm.visualizar,"rules":[
                _vm.validationService.required('Campo Logradouro é obrigatório.') ],"type":'text'},model:{value:(_vm.unidade.logradouro),callback:function ($$v) {_vm.$set(_vm.unidade, "logradouro", $$v)},expression:"unidade.logradouro"}})],1),_c('div',{staticClass:"col-lg-3 form-group clearfix mb-3"},[_c('label',{attrs:{"for":"Bairro"}},[_vm._v("Bairro")]),_c('v-text-field',{staticStyle:{"padding-top":"0 !important"},attrs:{"error-messages":_vm.error.bairro,"disabled":_vm.visualizar,"rules":[
                _vm.validationService.required('Campo Bairro é obrigatório.') ],"type":'text'},model:{value:(_vm.unidade.bairro),callback:function ($$v) {_vm.$set(_vm.unidade, "bairro", $$v)},expression:"unidade.bairro"}})],1),_c('div',{staticClass:"col-lg-3 form-group clearfix mb-3"},[_c('label',{attrs:{"for":"Numero"}},[_vm._v("Número")]),_c('v-text-field',{staticStyle:{"padding-top":"0 !important"},attrs:{"error-messages":_vm.error.numero,"disabled":_vm.visualizar,"type":'text',"rules":[
                _vm.validationService.required('Campo Número é obrigatório.') ]},model:{value:(_vm.unidade.numero),callback:function ($$v) {_vm.$set(_vm.unidade, "numero", $$v)},expression:"unidade.numero"}})],1),_c('div',{staticClass:"col-lg-3 form-group clearfix mb-3"},[_c('label',{attrs:{"for":"Complemento"}},[_vm._v("Complemento")]),_c('v-text-field',{staticStyle:{"padding-top":"0 !important"},attrs:{"error-messages":_vm.error.complemento,"disabled":_vm.visualizar,"type":'text'},model:{value:(_vm.unidade.complemento),callback:function ($$v) {_vm.$set(_vm.unidade, "complemento", $$v)},expression:"unidade.complemento"}})],1),_c('div',{staticClass:"col-lg-3 form-group clearfix mb-3"},[_c('label',{attrs:{"for":"Referencia"}},[_vm._v("Referência")]),_c('v-text-field',{staticStyle:{"padding-top":"0 !important"},attrs:{"error-messages":_vm.error.referencias,"disabled":_vm.visualizar,"type":'text'},model:{value:(_vm.unidade.referencias),callback:function ($$v) {_vm.$set(_vm.unidade, "referencias", $$v)},expression:"unidade.referencias"}})],1),_c('div',{staticClass:"col-lg-3 form-group clearfix mb-3"},[_c('label',{attrs:{"for":"Cidade"}},[_vm._v("Cidade")]),_c('v-text-field',{staticStyle:{"padding-top":"0 !important"},attrs:{"error-messages":_vm.error.cidade,"disabled":_vm.visualizar,"rules":[
                _vm.validationService.required('Campo Cidade é obrigatório.') ],"type":'text'},model:{value:(_vm.unidade.cidade),callback:function ($$v) {_vm.$set(_vm.unidade, "cidade", $$v)},expression:"unidade.cidade"}})],1),_c('div',{staticClass:"col-lg-3 form-group clearfix mb-3"},[_c('label',{attrs:{"for":"Estado"}},[_vm._v("Estado")]),_c('v-text-field',{staticStyle:{"padding-top":"0 !important"},attrs:{"error-messages":_vm.error.estado,"disabled":_vm.visualizar,"rules":[
                _vm.validationService.required('Campo Estado é obrigatório.') ],"type":'text'},model:{value:(_vm.unidade.estado),callback:function ($$v) {_vm.$set(_vm.unidade, "estado", $$v)},expression:"unidade.estado"}})],1),_c('div',{staticClass:"col-12 form-group"},[(!_vm.visualizar)?_c('button',{staticClass:"\n                btn btn-app-primary btn-flex btn-rounded\n                font-weight-bold\n                justify-content-end\n              ",on:{"click":function($event){$event.preventDefault();return _vm.atualizarUnidade()}}},[(!_vm.loading)?_c('span',[_vm._v(" Salvar ")]):_c('v-progress-circular',{attrs:{"indeterminate":"","color":"white"}})],1):_vm._e()])]),(_vm.messageSnackbar)?_c('v-snackbar',{attrs:{"color":_vm.typeMessageSnackbar,"timeout":-1},scopedSlots:_vm._u([{key:"action",fn:function(ref){
              var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":"","color":'white'},on:{"click":_vm.fecharSnackbar}},'v-btn',attrs,false),[_vm._v(" Ok ")])]}}],null,false,3805457681),model:{value:(_vm.messageSnackbar),callback:function ($$v) {_vm.messageSnackbar=$$v},expression:"messageSnackbar"}},[_vm._v(" "+_vm._s(_vm.messageSnackbar)+" ")]):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }