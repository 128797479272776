<template>
  <div class="w-100">
    <v-dialog v-model="dialogReceitaTelevet">
      <receita-televet
        v-if="dialogReceitaTelevet"
        :idTutor="idTutorTelevet"
        :idPet="idPetTelevet"
        @close="close()"
      />
    </v-dialog>
    <v-dialog v-model="dialogUploadExame">
      <UploadExames
        v-if="dialogUploadExame"
        :paciente="pacienteTelevet"
        :key="rederComp"
        @close="close()"
      />
    </v-dialog>
    <v-dialog
      v-model="dialogProntuarioTelevet"
      hide-overlay
      persistent
      fullscreen
      width="50%"
    >
      <prontuarios
        v-if="dialogProntuarioTelevet"
        :pacienteInfo="pacienteTelevet"
        :consultas="prontuarioTelevet"
        @close="close()"
      />
    </v-dialog>
    <v-dialog v-model="dialogArquivoTelevet">
      <arquivo
        v-if="dialogArquivoTelevet"
        :paciente="pacienteTelevet"
        :key="rederComp"
        @close="close()"
      />
    </v-dialog>
    <v-dialog v-model="dialogLoading" hide-overlay persistent width="300">
      <v-card color="white">
        <v-card-text>
          Carregando...
          <v-progress-linear
            indeterminate
            color="green lighten-3"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <table
      id="lista_agendamentos"
      class="table-crud agenda-lista"
      style="width: 100%"
    >
      <thead>
        <tr id="agenda-titles">
          <td></td>
          <td align="center">Horário</td>
          <td align="center">Paciente</td>
          <td align="center">Status</td>
          <td align="center">Atendimento</td>
          <td align="center">Ações</td>
          <td></td>
        </tr>
      </thead>
      <tbody v-for="(compromisso, id) in compromissos" :key="id">
        <template class="pl-2">
          <tr
            v-bind:key="compromisso.idAgendamento"
            class="CI green-dot [OPACITY_ROW] content ag-l-content"
          >
            <td
              class="color-row"
              :style="
                ` background: ${
                  compromisso.idConsulta
                    ? 'green'
                    : statusStyle(compromisso.status_sala)
                } !important`
              "
            ></td>

            <td>
              <strong>{{ compromisso.horarioInicio }}</strong
              ><br />
              <strong>{{ compromisso.horarioFinal }}</strong>
            </td>
            <td>
              <span
                @click="mostraPerfilPaciente(compromisso)"
                class="btnSelecionarPaciente"
              >
                {{ compromisso.idCliente }} - {{ compromisso.nomePaciente }}
              </span>
              <br />
              <span class="h5">Pet:</span>
              <span> {{ compromisso.idPet }} - {{ compromisso.nomePet }}</span>
            </td>
            <td>
              {{
                compromisso.idConsulta
                  ? "ATENDIDO"
                  : compromisso.status_sala == 1
                  ? "EM ATENDIMENTO"
                  : statusText(compromisso.status_sala)
              }}
            </td>
            <td>
              <span class="tipo-proc">{{ compromisso.tipoProcedimento }}</span>
              <br />
              <span class="proc">{{ compromisso.procedimento }}</span>
            </td>
            <td>
              <div class="col-12 d-flex justify-content-center">
                <v-menu
                  :close-on-content-click="false"
                  :nudge-width="200"
                  offset-x
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-if="!compromisso.idConsulta"
                      :disabled="compromisso.status_sala > 1"
                      :class="compromisso.status_sala > 1 ? 'btn-disabled' : ''"
                      class="btn-call"
                      title="Entrar na Sala"
                      @click="salaConferencia(compromisso.idRequisicao)"
                    >
                      <v-icon>mdi-video-account </v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      v-if="!compromisso.idConsulta"
                      :disabled="compromisso.status_sala > 1"
                      :class="compromisso.status_sala > 1 ? 'btn-disabled' : ''"
                      class="ag-btn-enc"
                      title="Encerrar"
                      @click="openModalEncerrar(compromisso)"
                    >
                      <v-icon>mdi-alert-rhombus </v-icon>
                    </v-btn>
                    <button
                      v-if="!compromisso.idConsulta"
                      :disabled="compromisso.status_sala > 1"
                      :class="compromisso.status_sala > 1 ? 'btn-disabled' : ''"
                      class="ag-btn-atd mx-3 [ATENDIDO]"
                      title="Iniciar Anamnese"
                      v-bind="attrs"
                      v-on="on"
                      @click="openProntuariosAgendaOnly(compromisso)"
                    >
                      ATENDER
                    </button>

                    <!-- v-if="
                        compromisso.idRequisicao != 0 && idUnidadeStorage == 24
                      " -->
                  </template>
                  <v-container class="bg-white">
                    <h4>selecionar tipo de formulário</h4>
                    <v-select
                      v-model="edited.idFormulario"
                      @change="modalConsulta = true"
                      item-value="id"
                      item-text="titulo"
                      :items="formularios"
                      dense
                    ></v-select>
                  </v-container>
                </v-menu>
                <!-- Receita Televet -->
                <!-- <v-icon
                  v-if="compromisso.idConsulta"
                  size="19"
                  class="mr-2"
                  title="Receita"
                  @click="receitaTelevet(compromisso)"
                  >fas fa-capsules
                </v-icon> -->
                <v-icon
                  v-if="compromisso.idConsulta"
                  size="19"
                  class="mr-2"
                  title="Upload de exames"
                  @click="uploadExamesCall(compromisso)"
                  >mdi-file-upload-outline
                </v-icon>
                <v-icon
                  v-if="compromisso.idConsulta"
                  small
                  class="mr-2"
                  title="Documentos"
                  @click="arquivoTelevet(compromisso)"
                  >fas fa-folder
                </v-icon>
                <v-icon
                  v-if="compromisso.idConsulta"
                  color="primary"
                  size="19"
                  class="mr-2"
                  title="Prontuários"
                  @click="listProntuarios(compromisso)"
                  >fas fa-file-medical-alt
                </v-icon>
              </div>
            </td>
            <td></td>
          </tr>
          <tr v-bind:key="`it-${compromisso.idAgendamento}`" class="spacer">
            <td colspan="9" class="p-1"></td>
          </tr>
        </template>
      </tbody>
    </table>
    <v-dialog
      v-model="dialogPerfil"
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <cadastro-edicao-paciente
        v-if="dialogPerfil"
        :paciente="paciente"
        :visualizar="false"
        @close="dialogPerfil = false"
      />
    </v-dialog>

    <!-- dialogs prontuario view -->
    <!-- DIALOG FOTOS CLASSIFICADAS -->
    <v-dialog
      v-model="dialogFotosClassificadas"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <fotos-classificadas
        :paciente="edited_paciente_id"
        :key="rederComp"
        @close="close()"
      />
    </v-dialog>
    <!-- DIALOG DE PRONTUÁRIO -->
    <v-dialog
      v-model="dialogP"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <prontuarios :consultas="prontuarios" @close="dialogP = false" />
    </v-dialog>

    <!-- DIALOG DE PRONTUÁRIO -->
    <v-dialog
      v-model="dialogGaleria"
      fullscreen
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
    >
      <galerias
        :paciente="{ id_paciente: edited_paciente_id }"
        :key="rederComp"
        @close="close()"
      />
    </v-dialog>
    <v-dialog
      v-model="dialogArquivo"
      fullscreen
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
    >
      <arquivo :paciente="pacienteTelevet" :key="rederComp" @close="close()" />
    </v-dialog>
    <v-dialog v-model="dialogAtestado" hide-overlay persistent width="50%">
      <atestado @close="close()" @gerarPDF="gerarPDF($event)" />
    </v-dialog>
    <v-dialog v-model="dialogExames" hide-overlay persistent style="width: 50%">
      <exames @close="close()" @gerarPDF="gerarPDF($event)" />
    </v-dialog>
    <v-dialog
      v-model="dialogEncaminhamento"
      hide-overlay
      persistent
      width="30%"
    >
      <encaminhamento @close="close()" @gerarPDF="gerarPDF($event)" />
    </v-dialog>
    <v-dialog v-model="dialogReceita" hide-overlay persistent width="50%">
      <receita @close="close()" @gerarPDF="gerarPDF($event)" />
    </v-dialog>
    <v-dialog v-model="dialogTermo" hide-overlay persistent width="40%">
      <termos @close="close()" @gerarPDF="gerarPDF($event)" />
    </v-dialog>
    <v-dialog
      v-model="dialogReceitaCarbonada"
      hide-overlay
      persistent
      width="50%"
    >
      <receita-carbonada @close="close()" @gerarPDF="gerarPDF($event)" />
    </v-dialog>

    <v-dialog v-model="dialogEncerrarTelevet" persistent maxWidth="500">
      <modal-encerrar-consulta-telemedicina
        v-if="dialogEncerrarTelevet"
        :consultaTelevet="consultaTelevet"
        @close="dialogEncerrarTelevet = false"
        @response="finalizarConsulta($event)"
      />
    </v-dialog>
    <v-dialog
      v-model="modalConsulta"
      v-if="modalConsulta"
      fullscreen
      scrollable
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
    >
      <!-- :paciente="compromisso" -->
      <modal-iniciar-consulta-telemedicina
        @close="closeModalConsulta"
        :paciente="consultaTelevet"
        :info="{
          idAgendamento: edited.idAgendamento,
          idFormulario: edited.idFormulario,
        }"
        :idRequisicao="edited.id_requisicao"
        @finalizarConsulta="finalizarConsulta($event)"
        @concluido="this.$emit('atualizarCompromissos')"
      />
    </v-dialog>

    <!-- EXAMES PDF -->
    <div id="examesPDF" ref="examesPDF" v-show="false">
      <div id="header" v-if="htmlToPdf != 'receita-carbonada'">
        <!-- <img src="@/assets/images/logo-horizontal.png" alt="" /> -->
        <img src="@/assets/images/Logotipo-Televet-Play-Color.png" alt="" />
      </div>
      <div id="main">
        <!-- DOC EXAMES -->
        <div class="d-flex flex-column w-100" v-if="htmlToPdf == 'exames'">
          <p>PARA:<span class="space">_</span>{{ nome }}</p>
          <p><b>Solicito:</b></p>
          <u v-for="(exame, i) in exames" :key="i">
            <li class="text-nowrap resp">{{ i + 1 }} {{ exame }}</li>
          </u>
          <div>
            <p>OBS.:<span class="space">_</span>{{ obs }}</p>
            <p v-if="obs2 != ''">OBS.:<span class="space">_</span>{{ obs2 }}</p>
            <p class="d-flex flex-column ml-auto">
              <span> _________________________________________</span>
              <span class="ml-2">Luiz Nestor Vasconcelos Rodrigues </span>
              <span class="ml-1 resp"
                >Cirurgião Plástico - CRM 6110 - RQE 2395</span
              >
            </p>
          </div>
        </div>
        <!-- DOC ATESTADO -->
        <div v-else-if="htmlToPdf == 'atestado'">
          <p>Atestado Médico</p>
          <p class="indent resp">
            Atesto para devidos fins que <b>{{ nome }}</b> , necessita
            afastar-se de suas atividades laborais por um período de
            <b>{{ dias }}</b> dias, por motivo de procedimento cirúrgico.
          </p>

          <p>CID: {{ codigoCFM }}</p>
          <div class="d-flex flex-column text-end pt-8">
            <p class="ml-auto">
              Fortaleza, {{ gerarData().dia
              }}<span class="space">_</span>de<span class="space">_</span
              >{{ gerarData().mes }}<span class="space">_</span
              >{{ gerarData().ano }}.
            </p>
            <p class="d-flex flex-column ml-auto">
              <span> _________________________________________</span>
              <span class="ml-1"
                >Luiz Nestor Vasconcelos Rodrigues - CRM 6110
              </span>
            </p>
          </div>
        </div>
        <!-- DOC RECEITA -->
        <div
          class="d-flex flex-column w-100 resp"
          v-else-if="htmlToPdf == 'receita'"
          style="margin-bottom: 30px"
        >
          <div>
            <p>
              PARA:<span class="space">_</span><b>{{ nome }}</b>
            </p>
            <p class="m-auto text-center"><b>Medicações</b></p>
          </div>
          <u v-for="(item, i) in receita" :key="i">
            <li class="d-flex flex-column resp">
              <p class="m-auto text-center" v-if="item.titulo">
                <strong
                  ><u>{{ item.titulo }}</u></strong
                >
              </p>
              <p class="resp">
                {{ i + 1 }} - {{ item.medicamento }}_______{{ item.quantidade
                }}<br />&nbsp;&nbsp;&nbsp;&nbsp;{{ item.posologia }}
              </p>
            </li>
          </u>
          <div class="d-flex flex-column text-end pt-8">
            <p class="ml-auto">
              Fortaleza, {{ gerarData().dia
              }}<span class="space">_</span>de<span class="space">_</span
              >{{ gerarData().mes }}<span class="space">_</span
              >{{ gerarData().ano }}.
            </p>
          </div>
          <p class="d-flex flex-column ml-auto">
            <span> _________________________________________</span>
            <span class="ml-2">Luiz Nestor Vasconcelos Rodrigues </span>
            <span class="ml-1 resp"
              >Cirurgião Plástico - CRM 6110 - RQE 2395</span
            >
          </p>
        </div>
        <!-- DOC RECEITA CARBONADA -->
        <div class="carbonada" v-else-if="htmlToPdf == 'receita-carbonada'">
          <div class="row row-cols-2" style="width: 18rem">
            <div class="col" style="border-right: solid gray 0.01px !important">
              <b>RECEITUÁRIO DE CONTROLE ESPECIAL</b><br />
              <div class="row row-cols-2 resp">
                <div class="resp col col-8 pt-6">
                  <div
                    class="resp"
                    style="
                      border: solid gray 0.1px !important;
                      width: 4.5rem !important;
                      padding-right: 1px;
                      padding-left: 1px;
                    "
                  >
                    <span
                      class="ml-1 mt-2 pb-0 resp"
                      style="padding-top: 1px"
                    ></span
                    ><br />
                    <span class="ml-1 mt-2 pb-0 resp" style="padding-top: 1px"
                      >IDENTIFICAÇÃO DO EMITENTE</span
                    ><br />
                    <span class="text-decoration-underline resp p-0 mt-0"
                      >__________________________________________</span
                    ><br />
                    <span style="letter-spacing: 0.2px; font-size: 2.6px"
                      ><b>Dr. Luiz Nestor Vasconcelos Rodrigues</b></span
                    ><br />
                    <span style="letter-spacing: 0.2px; font-size: 2.6px"
                      >Cirurgia Plástica - CRM 6110 - RQE 2395</span
                    ><br />
                    <span style="letter-spacing: 0.2px; font-size: 2.6px"
                      >End.: Riomar Trade Center</span
                    ><br />
                    <span style="letter-spacing: 0.2px; font-size: 2.6px"
                      >Rua Desembargador Lauro Nogueira, 1500. Papicu</span
                    >
                    <br />
                    <span style="letter-spacing: 0.2px; font-size: 2.6px">
                      10º andar - sala 1017</span
                    ><br />
                    <span style="letter-spacing: 0.2px; font-size: 2.6px"
                      >Fone:(85) 99985.1522 / (85)3039.3890</span
                    ><br />
                  </div>
                </div>
                <div class="resp col col-4 p-0 pt-2">
                  1º via Farmácia <br />
                  2º via Farmácia <br /><br /><br /><br />
                  ___________________________ <br />
                  Carimbo e Assinatura <br />
                </div>
              </div>
            </div>
            <div class="col">
              <b>RECEITUÁRIO DE CONTROLE ESPECIAL</b><br />
              <div class="row row-cols-2 resp receituario_controle">
                <div class="resp col col-8 pt-6">
                  <div
                    class="resp"
                    style="
                      border: solid gray 0.1px !important;
                      width: 4.5rem !important;
                      padding-right: 1px;
                      padding-left: 1px;
                    "
                  >
                    <span
                      class="ml-1 mt-2 pb-0 resp"
                      style="padding-top: 1px"
                    ></span
                    ><br />
                    <span class="ml-1 mt-2 pb-0 resp" style="padding-top: 1px"
                      >IDENTIFICAÇÃO DO EMITENTE</span
                    ><br />
                    <span class="text-decoration-underline resp p-0 mt-0"
                      >__________________________________________</span
                    ><br />
                    <span style="letter-spacing: 0.2px; font-size: 2.6px"
                      ><b>Dr. Luiz Nestor Vasconcelos Rodrigues</b></span
                    ><br />
                    <span style="letter-spacing: 0.2px; font-size: 2.6px"
                      >Cirurgia Plástica - CRM 6110 - RQE 2395</span
                    ><br />
                    <span style="letter-spacing: 0.2px; font-size: 2.6px"
                      >End.: Riomar Trade Center</span
                    ><br />
                    <span style="letter-spacing: 0.2px; font-size: 2.6px"
                      >Rua Desembargador Lauro Nogueira, 1500. Papicu</span
                    >
                    <br />
                    <span style="letter-spacing: 0.2px; font-size: 2.6px">
                      10º andar - sala 1017</span
                    ><br />
                    <span style="letter-spacing: 0.2px; font-size: 2.6px"
                      >Fone:(85) 99985.1522 / (85)3039.3890</span
                    ><br />
                  </div>
                </div>
                <div class="resp col col-4 p-0 pt-2">
                  1º via Farmácia <br />
                  2º via Farmácia <br /><br /><br /><br />
                  ___________________________ <br />
                  Carimbo e Assinatura <br />
                </div>
              </div>
            </div>
          </div>
          <div
            class="row rows-cols-2 pt-0"
            style="width: 18rem; padding-top: 0px"
          >
            <div
              class="col"
              style="
                border-right: solid gray 0.01px !important;
                padding-top: 0px;
              "
            >
              Paciente: <b>{{ nome }}</b
              ><br />
              Endereço: {{ logradouro }}, {{ numero }}. {{ complemento }} <br />
              <u v-for="(item, i) in receita" :key="i">
                <li class="d-flex flex-column resp">
                  {{ i + 1 }} - {{ item.medicamento }}_______{{ item.quantidade
                  }}<br />&nbsp;&nbsp;&nbsp;&nbsp;{{ item.posologia }}
                </li> </u
              ><br /><br />
              <span class="resp pb-0 mb-0"
                >DATA: {{ new Date().toLocaleDateString() }}</span
              >
            </div>
            <div class="col" style="padding-top: 0px">
              Paciente: <b>{{ nome }}</b
              ><br />
              Endereço: {{ logradouro }}, {{ numero }}. {{ complemento }} <br />
              <u v-for="(item, i) in receita" :key="i">
                <li class="d-flex flex-column resp">
                  {{ i + 1 }} - {{ item.medicamento }}_______{{ item.quantidade
                  }}<br />&nbsp;&nbsp;&nbsp;&nbsp;{{ item.posologia }}
                </li> </u
              ><br /><br />
              <span class="resp pb-0 mb-0"
                >DATA: {{ new Date().toLocaleDateString() }}</span
              >
            </div>
          </div>
          <div
            class="row rows-cols-2 pt-0 pr-0"
            style="width: 18rem; padding: 0px"
          >
            <div
              class="col"
              style="
                border-right: solid gray 0.01px !important;
                margin-left: 1px;
              "
            >
              <div
                class="row rows-cols-2"
                style="border: solid gray 0.01px !important"
              >
                <div
                  class="col pt-0"
                  style="border-right: solid gray 0.01px !important"
                >
                  <span>IDENTIFICAÇÃO DO COMPRADOR</span><br />
                  <br /><br /><br />
                  <span>Nome Completo_______________________</span><br />
                  <span>_______________________________________</span><br />
                  <span>IDENT:______________ORG.EMISSOR______</span><br />
                  <span>END:__________________________________</span><br />
                  <span>______________________________________</span><br />
                  <span>Cidade:______________UF:_______________</span><br />
                  <span>Tel:_____________________________</span><br />
                </div>
                <div class="col pt-0 pl-1" style="padding-right: 0px">
                  <span> IDENTIFICAÇÃO DO FORNECEDOR </span>
                  <br /><br /><br /><br /><br /><br /><br /><br />
                  <span>______________________________</span><br />
                  <span class="ml-1">Assinatura Farmacêutico</span>
                </div>
              </div>
            </div>
            <div class="col">
              <div
                class="row rows-cols-2"
                style="
                  border-top: solid gray 0.01px !important;
                  border-right: solid gray 0.01px !important;
                  border-bottom: solid gray 0.01px !important;
                "
              >
                <div
                  class="col pt-0"
                  style="border-right: solid gray 0.01px !important"
                >
                  <span>IDENTIFICAÇÃO DO COMPRADOR</span><br />
                  <br /><br /><br />
                  <span>Nome Completo_______________________</span><br />
                  <span>_______________________________________</span><br />
                  <span>IDENT:______________ORG.EMISSOR______</span><br />
                  <span>END:__________________________________</span><br />
                  <span>______________________________________</span><br />
                  <span>Cidade:______________UF:_______________</span><br />
                  <span>Tel:_____________________________</span><br />
                </div>
                <div class="col pt-0 pl-1" style="padding-right: 0px">
                  <span> IDENTIFICAÇÃO DO FORNECEDOR </span>
                  <br /><br /><br /><br /><br /><br /><br /><br />
                  <span>______________________________</span><br />
                  <span class="ml-1">Assinatura Farmacêutico</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- DOC TERMOS -->
        <div
          class="d-flex flex-column w-100 resp"
          v-else-if="htmlToPdf == 'termo'"
        >
          <div style="height: 4vh; width: 4vw" v-if="tipoTermo == 'termo_um'">
            <img src="@/assets/images/SBCP.jpg" alt="" />
          </div>
          <p class="resp text-center" v-if="tipoTermo == 'termo_um'">
            <b>TERMO DE RESPONSABILIDADE EXPRESSO</b>
          </p>
          <p
            class="resp indent"
            style="font-size: 3px !important; margin-bottom: 20px"
            v-if="tipoTermo == 'termo_um'"
          >
            O (a) paciente ou responsável pelo paciente, Sr. (a)
            <b>{{ nome }}</b
            >, sob o CPF:{{ cpf }}, aqui declarado, dá plena autorização ao seu
            médico assistente, Dr. LUIZ NESTOR VASCONCELOS RODRIGUES C.R.M. Nº.
            6110, para fazer as investigações necessárias ao diagnóstico e
            executar tratamentos, operações, anestesias, transfusões de sangue,
            ou outras condutas médicas que venham de encontro às necessidades
            clínico-cirúrgicas do caso, bem como dá ciência de informações sobre
            sua capacidade técnico profissional e moral, comprometendo-se a
            respeitar as instruções que lhe forem fornecidas, inclusive quanto
            aos imprevistos oriundos da eventualidade como infecções, necroses
            de retalhos ou regiões operadas, assimetrias, deiscências, seromas,
            rejeições, no caso de próteses, embolia pulmonar, trombose venosa
            profunda, entre outras complicações inerentes aos procedimentos.
            Outrossim, sou consciente dos riscos informados, incluindo o de
            morte, ou da não observância das orientações médicas
            recomendadas.<br />
            CIRURGIA (S) PROPOSTAS (S): (indicação):<br />
            {{ procedimento }}
          </p>
          <div class="d-flex flex-column" v-if="tipoTermo == 'termo_um'">
            <p class="ml-auto">
              Fortaleza, {{ gerarData().dia
              }}<span class="space">_</span>de<span class="space">_</span
              >{{ gerarData().mes }}<span class="space">_</span
              >{{ gerarData().ano }}.
            </p>
            <p class="d-flex flex-column mr-auto">
              <span> _________________________________________</span>
              <span>(Assinatura paciente ou responsável legal) </span>
            </p>
            <p class="d-flex flex-column mr-auto resp">
              NOME POR EXTENSO: {{ nome }}<br />
              RG. Ou CPF.: {{ cpf }}
            </p>
          </div>
          <p class="resp text-center" v-if="tipoTermo == 'termo_dois'">
            <b>TERMO DE CONSENTIMENTO INFORMADO EXPRESSO</b>
          </p>
          <p class="resp indent" v-if="tipoTermo == 'termo_dois'">
            Declaro que recebi o "TERMO DE INFORMAÇÕES PRÉ-CIRÚRGICAS" como
            também todos os esclarecimentos necessários sobre as informações
            específicas contidas neste termo sobre a cirurgia a ser realizada,
            onde foram explicados os detalhes sobre as cicatrizes resultantes,
            outros estados orgânicos, eventuais intercorrências, evoluções
            favoráveis e desfavoráveis pós-cirúrgicas, infecções, deiscências,
            necroses, assimetrias, perda da sensibilidade, rejeições, no caso de
            próteses, embolia pulmonar, trombose venosa profunda, inclusive
            risco de morte, entre outras complicações do procedimento bem como
            os cuidados para minimizar esses problemas. Assim, livremente e de
            espontânea vontade, assino (amos) o presente TERMO DE CONSENTIMENTO
            PÓS-INFORMADO para que o Dr. LUIZ NESTOR VASCONCELOS RODRIGUES,
            C.R.M. Nº. 6110 possa realizar o procedimento clínico-cirúrgico de e
            para que produza os devidos efeitos legais.
          </p>
          <div class="d-flex flex-column" v-if="tipoTermo == 'termo_dois'">
            <p class="ml-auto">
              Fortaleza, {{ gerarData().dia
              }}<span class="space">_</span>de<span class="space">_</span
              >{{ gerarData().mes }}<span class="space">_</span
              >{{ gerarData().ano }}.
            </p>
            <p class="d-flex flex-column mr-auto">
              <span> _________________________________________</span>
              <span>Paciente</span>
            </p>
            <p class="d-flex flex-column mr-auto">
              <span> _________________________________________</span>
              <span>(Responsável legal, qdo.menor)</span>
            </p>
          </div>
          <p class="resp text-center" v-if="tipoTermo == 'termo_tres'">
            <b>TERMO DE CONSENTIMENTO LIVRE E ESCLARECIDO</b>
          </p>
          <p class="resp indent" v-if="tipoTermo == 'termo_tres'">
            Eu <b>{{ nome }}</b
            >, Declaro para devidos fins que estou ciente de que realizarei
            procedimento cirúrgico em vigência da pandemia de infecção pelo
            COVID-19, com potencial risco de infecção, uma vez que a infecção é
            comunitária. Também estou ciente que o procedimento que serei
            submetida <b>{{ procedimento }}</b
            >, em riscos anestésico cirúrgicas, tendo potencial necessidade de
            internação em unidade de terapia intensiva (U.T.I.), que, no momento
            estão sendo utilizadas para pacientes portadores de COVID-19 (CORONA
            VÍRUS). Portando, estou ciente de que, em caso de internação em
            U.T.I., estarei em um ambiente sem isolamento e em contato próximo
            com pacientes com COVID -19. Tendo alto risco de contaminação por
            este vírus.
          </p>
          <div class="d-flex flex-column" v-if="tipoTermo == 'termo_tres'">
            <p class="ml-auto">
              Fortaleza, {{ gerarData().dia
              }}<span class="space">_</span>de<span class="space">_</span
              >{{ gerarData().mes }}<span class="space">_</span
              >{{ gerarData().ano }}.
            </p>
            <div class="d-flex flex-row justify-space-between">
              <p class="d-flex flex-column mr-auto">
                <span> _____________________________________</span>
                <span class="ml-1">Assinatura Paciente/ Responsável</span><br />
                <span class="ml-1"> CPF: {{ cpf }}</span>
              </p>
              <p class="d-flex flex-column mr-auto">
                <span> _____________________________________</span>
                <span class="ml-3">Testemunha</span><br />
                CPF:______________________________
              </p>
            </div>
          </div>
        </div>
        <!-- DOC ENCAMINHAMENTO -->
        <div
          class="d-flex flex-column"
          v-else-if="htmlToPdf == 'encaminhamento'"
        >
          <p class="resp">{{ nomeMedico }}</p>
          <p class="resp">
            Encaminho o(a) paciente <b>{{ nome }}</b
            >, para avaliação e conduta que julgar necessária.
          </p>
          <p class="ml-auto">Agradeço a atenção.</p>
          <div class="d-flex flex-column text-end pt-8">
            <p class="ml-auto">
              Fortaleza, {{ gerarData().dia
              }}<span class="space">_</span>de<span class="space">_</span
              >{{ gerarData().mes }}<span class="space">_</span
              >{{ gerarData().ano }}.
            </p>
            <p class="d-flex flex-column ml-auto">
              <span> _________________________________________</span>
              <span class="ml-2">Luiz Nestor Vasconcelos Rodrigues </span>
              <span class="ml-1 resp"
                >Cirurgião Plástico - CRM 6110 - RQE 2395</span
              >
            </p>
          </div>
        </div>
      </div>
      <div id="footer" v-if="htmlToPdf != 'receita-carbonada'">
        <img src="@/assets/images/footer.jpg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import FormularioService from "@/services/formulario_service.js";
// import ConsultaService from "@/services/consulta_service.js";
import PacienteService from "@/services/paciente_service.js";
import CadastroEdicaoPaciente from "@/components/pacientes/cadastro_edicao_paciente.vue";
import PetsService from "@/services/pets_service.js";

import Atestado from "@/components/documentos/atestado.vue";
import Encaminhamento from "@/components/documentos/encaminhamento.vue";
import Exames from "@/components/documentos/exames.vue";
import Receita from "@/components/documentos/receita.vue";
import ReceitaCarbonada from "@/components/documentos/receita_carbonada.vue";
import Termos from "@/components/documentos/termos.vue";
import Galerias from "@/components/prontuarios/galeria.vue";
import Arquivo from "@/components/prontuarios/arquivos.vue";
import Prontuarios from "@/components/prontuarios/prontuarios.vue";
import ProfissionalService from "@/services/profissional_service.js";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import FotosClassificadas from "@/components/prontuarios/fotos_classificadas.vue";

import AgendamentoSalaService from "@/services/agendamento_sala_service.js";
import ModalIniciarConsultaTelemedicina from "../consulta_telemedicina/modal_iniciar_consulta_telemedicina.vue";
import ModalEncerrarConsultaTelemedicina from "../encerrar_telemedicina/modal_encerrar_consulta_telemedicina.vue";

import UploadExames from "@/components/prontuarios/uploadExames.vue";
import ReceitaTelevet from "@/components/documentos/receita_televet.vue";

export default {
  components: {
    ModalIniciarConsultaTelemedicina,
    ModalEncerrarConsultaTelemedicina,
    CadastroEdicaoPaciente,
    Prontuarios,
    Galerias,
    Arquivo,
    Atestado,
    Exames,
    Encaminhamento,
    Receita,
    ReceitaCarbonada,
    Termos,
    FotosClassificadas,
    UploadExames,
    ReceitaTelevet,
  },
  props: {
    compromissos: { type: Array, required: true },
    consultaId: { type: Number, required: true },
  },
  beforeMount() {
    console.log(this.compromissos);
    this.selectNext();
  },

  watch: {
    // consultaId() {
    //
    // }
  },

  data: () => ({
    dialogRequisitarExame: {},
    dialogRequisitarCirurgia: {},
    dialogRequisitarMedicacao: {},
    dialogRequisitarConsulta: {},
    modalConsulta: false,
    dialogLoading: false,
    rederComp: 0,
    prontuarios: [],
    formularios: [],
    dialogRequisitarInternacao: {},
    formulario: "",
    renderComp: 0,
    procedimento: "",
    indexCompromissoSelecionado: 0,
    idAgendamentoSelecionado: null,
    loadingFormularios: false,
    telaCarregada: false,
    dialog: false,
    procedimentoAtendido: {},
    edited: {},
    paciente: {},
    dialogPerfil: false,
    dialogCadastro: false,
    dialogGaleria: false,
    dialogFotosClassificadas: false,
    visualizar: false,
    pacientes: [],
    htmlToPdf: "",
    // Documentos
    arquivos: [],
    dialogArquivo: false,
    // Atestado
    dialogAtestado: false,
    dialogP: false,
    nome: "",
    dias: "",
    codigoCFM: "",
    //Encaminhamento
    dialogEncaminhamento: false,
    nomeMedico: "",
    //Solicitar Exame
    dialogExames: false,
    exames: [],
    obs: "",
    edited_paciente_id: 0,
    obs2: "",
    //Receita Nova
    dialogReceita: false,
    receita: "",
    //Receita Carbonada
    dialogReceitaCarbonada: false,
    logradouro: "",
    numero: "",
    complemento: "",
    //Termos
    dialogTermo: false,
    tipoTermo: "",
    cpf: "",
    agendamentoSalaService: AgendamentoSalaService.build(),
    idUnidadeStorage: JSON.parse(sessionStorage.getItem("vuex")).unidade.id,
    petService: PetsService.build(),
    dialogUploadExame: false,
    dialogArquivoTelevet: false,
    dialogProntuarioTelevet: false,
    dialogReceitaTelevet: false,
    prontuarioTelevet: [],
    pacienteTelevet: {},
    idTutorTelevet: "",
    idPetTelevet: "",
    dialogEncerrarTelevet: false,
    consultaTelevet: "",
  }),
  computed: {
    compromissosNaoAtrasados() {
      return this.compromissos.filter((compromisso) => !compromisso.emAtraso);
    },
    procedimentoPossuiFormularios() {
      return this.formularios.length > 0;
    },
  },
  methods: {
    teste(req) {
      console.log(req);
    },
    finalizarConsulta() {
      // console.log({ e });
      // this.dialogRequisitarExame = true;
      this.$emit("atualizarCompromissos");
    },
    mostraPerfilPaciente(cmp) {
      this.loadingPacienteAndOpen(cmp.paciente_id);
    },

    atualizarListaPacientes() {
      const profissionalService = new ProfissionalService();
      this.loading = true;
      this.pacientes = [];
      const onSucess = (status) => (body) => {
        if (status === 200) {
          this.totalList = body.data.pagination.num_rows;

          this.pacientes = body.data.pacientes.map((paciente) => {
            return paciente;
          });

          if (this.buscarEspecifico) {
            this.pacientes = this.pacientes.filter(
              (paciente) => paciente.id == this.$router.params.paciente.id
            );
          }
        } else {
          console.log(body);
        }
      };
      const onError = (error) => {
        console.log(error);
      };
      const onEnd = () => {
        this.loading = false;
      };

      this.search = this.buscarEspecifico
        ? this.$router.params.paciente.nome
        : this.search;

      profissionalService.getPacienteList(
        onSucess,
        onError,
        onEnd,
        this.options.page,
        this.options.itemsPerPage,
        this.search,
        this.options.ordenacao,
        this.options.desc
      );

      if (this.buscarEspecifico) {
        this.buscarEspecifico = false;
      }
    },
    gerarAtestado(item) {
      this.nome = item.nome;
      this.dialogAtestado = true;
    },
    gerarEncaminhamento(item) {
      this.nome = item.nome;
      this.dialogEncaminhamento = true;
    },
    gerarSolicitacaoDeExame(item) {
      this.nome = item.nome;
      this.dialogExames = true;
    },
    gerarReceitasNovas(item) {
      this.nome = item.nome;
      this.dialogReceita = true;
    },
    gerarReceitasCarbonada(item) {
      this.nome = item.nome;
      this.logradouro = item.logradouro;
      this.numero = item.numero;
      this.complemento = item.complemento;
      this.dialogReceitaCarbonada = true;
    },
    gerarTermos(item) {
      this.nome = item.nome;
      this.cpf = item.cpf;
      this.dialogTermo = true;
    },
    gerarData() {
      const meses = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novovembro",
        "Dezembro",
      ];
      let data = new Date();
      let dataFormatada = {
        dia: data.getDate(),
        mes: meses[data.getMonth()],
        ano: data.getFullYear(),
      };
      return dataFormatada;
    },

    gerarPDF(info) {
      switch (info.doc) {
        case "atestado":
          this.htmlToPdf = info.doc;
          this.dias = info.dias;
          this.codigoCFM = info.codigoCFM;
          this.$nextTick(() => {
            this.close();
            this.criarPDF(this.$refs.examesPDF.innerHTML, "Atestado", "padrao");
          });
          break;
        case "termo":
          this.htmlToPdf = info.doc;
          this.tipoTermo = info.termo_tipo;
          this.procedimento = info.procedimento;
          this.$nextTick(() => {
            this.close();
            this.criarPDF(
              this.$refs.examesPDF.innerHTML,
              "Termo de Responsabilidade",
              "padrao"
            );
          });
          break;
        case "receita":
          this.htmlToPdf = info.doc;
          this.receita = info.receita;
          this.$nextTick(() => {
            this.close();
            this.criarPDF(this.$refs.examesPDF.innerHTML, "Receita", "padrao");
          });
          break;
        case "receita-carbonada":
          this.htmlToPdf = info.doc;
          this.receita = info.receita;

          this.$nextTick(() => {
            this.close();
            this.criarPDF(
              this.$refs.examesPDF.innerHTML,
              "Receita-carbonada",
              "carbonada"
            );
          });
          break;
        case "encaminhamento":
          this.htmlToPdf = info.doc;
          this.nomeMedico = info.nomeMedico;
          this.$nextTick(() => {
            this.close();
            this.criarPDF(
              this.$refs.examesPDF.innerHTML,
              "Encaminhamento",
              "padrao"
            );
          });
          break;
        case "exames":
          this.htmlToPdf = info.doc;
          this.exames = info.exames;
          this.obs = info.obs;
          this.obs2 = info.obs2;
          this.$nextTick(() => {
            this.close();
            this.criarPDF(
              this.$refs.examesPDF.innerHTML,
              "Solicitação de exames",
              "padrao"
            );
          });
          break;

        default:
          console.log("Nenhum tipo de documento encontrado");
          break;
      }
    },
    criarPDF(content, nome, tipo) {
      window.html2canvas = html2canvas;
      if (tipo == "padrao") {
        var doc = new jsPDF("p", "mm", "a4");
        doc.html(content, {
          callback: function(pdf) {
            pdf.save(nome + ".pdf");
          },
          x: 5,
          y: 5,
        });
      } else {
        var docm = new jsPDF("l", "mm", "a4");
        docm.html(content, {
          callback: function(pdf) {
            pdf.save(nome + ".pdf");
          },
          x: 1,
          y: 1,
        });
      }
    },

    // listProntuarios(item) {
    //   this.paciente = item;
    //   this.dialogLoading = true;
    //   const consultaService = new ConsultaService();
    //   consultaService.getListByPacienteId(
    //     {
    //       onSucess: (status) => (body) => {
    //         if (status === 200) {
    //           console.log("body prontuarios");
    //           console.log(body.data);
    //           this.prontuarios = body.data.consultas;
    //           this.dialogP = true;
    //         }
    //       },
    //       onError: (error) => {
    //         console.log(error);
    //       },
    //       onEnd: () => {
    //         this.dialogLoading = false;
    //       },
    //     },
    //     item
    //   );
    // },

    async listProntuarios(item) {
      this.pacienteTelevet = item;
      this.dialogLoading = true;
      await this.petService
        .getConsultasbyUnidade(
          item.idPet,
          JSON.parse(sessionStorage.vuex).unidade.id
        )
        .then(async (response) => {
          const data = await response.json();
          if (response.status !== 200) {
            this.$_ACTIONS_showSnackbarMessage({
              message: data.message,
              color: response.status === 200 ? "sucess" : "error",
            });
          }
          this.prontuarioTelevet = data.data.consultas;
          this.pacienteTelevet = item;
          this.pacienteTelevet.id_cliente = item.idCliente;
          this.pacienteTelevet.id_pet = item.idPet;
          this.pacienteTelevet.nome = item.nomePaciente;
          this.pacienteTelevet.nome_pet = item.nomePet;
          this.dialogProntuarioTelevet = true;
        })
        .finally(() => {
          this.dialogLoading = false;
        });
    },

    forceRender() {
      this.rederComp += 1;
    },

    receitaTelevet(item) {
      this.idTutorTelevet = item.idPaciente;
      this.idPetTelevet = item.idPet;
      this.dialogReceitaTelevet = true;
      this.forceRender();
    },
    arquivoTelevet(item) {
      this.pacienteTelevet = {
        id_paciente: item.idPaciente,
        id_pet: item.idPet,
      };
      this.dialogArquivoTelevet = true;
      this.forceRender();
    },

    listGalerias(item) {
      this.edited_paciente_id = item;
      this.dialogGaleria = true;
      this.forceRender();
    },

    listFotosClassificadas(item) {
      this.edited_paciente_id = item;
      this.dialogFotosClassificadas = true;
      this.forceRender();
    },

    close() {
      this.dialog = false;
      this.dialogGaleria = false;
      this.dialogFotosClassificadas = false;
      this.dialogAtestado = false;
      this.dialogEncaminhamento = false;
      this.dialogExames = false;
      this.dialogReceita = false;
      this.dialogReceitaCarbonada = false;
      this.dialogTermo = false;
      this.dialogArquivo = false;
      this.dialogUploadExame = false;
      this.dialogProntuarioTelevet = false;
      this.dialogArquivoTelevet = false;
      this.dialogReceitaTelevet = false;
      this.forceRender();
    },

    loadingPaciente(id) {
      this.paciente = {};
      const pacienteService = new PacienteService();
      pacienteService.get(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.paciente = body.data;
              this.dialog = true;
            }

            this.dialog = true;
          },
          onError: (error) => {
            this.messageSnackbar = error;
            this.typeMessageSnackbar = "error";
            console.log(error);
          },
          onEnd: () => {},
        },
        id
      );
    },

    loadingPacienteAndOpen(id) {
      this.paciente = {};
      const pacienteService = new PacienteService();
      pacienteService.get(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.paciente = body.data;
              this.dialog = true;
            }

            this.dialog = true;
          },
          onError: (error) => {
            this.messageSnackbar = error;
            this.typeMessageSnackbar = "error";
            console.log(error);
          },
          onEnd: () => {
            this.dialogPerfil = true;
          },
        },
        id
      );
    },

    reloadConsultas() {
      this.selectNext();
    },

    openProntuariosAgenda(cmp) {
      this.loadingPaciente(cmp.paciente_id);
      this.edited = cmp;
      this.carregarTiposFormularios();

      this.modalConsulta = true;
    },

    openProntuariosAgendaOnly(cmp) {
      console.log(cmp);
      // this.loadingPaciente(cmp.paciente_id);
      this.consultaTelevet = cmp;

      this.loadingPaciente(cmp.idPaciente);
      this.carregarTiposFormulariosPorProcedimento(cmp.idProcedimento);
      this.edited = cmp;
    },
    openModalEncerrar(compromisso) {
      this.consultaTelevet = compromisso;
      this.dialogEncerrarTelevet = true;
    },

    selectNext() {
      let compromisso = {};
      for (
        let index = 0;
        index < this.compromissosNaoAtrasados.length;
        index++
      ) {
        compromisso = this.compromissosNaoAtrasados[index];
        if (compromisso.idConsulta == null) {
          this.selecionarAgendamento(function() {}, compromisso);
          this.procedimento = compromisso.idProcedimento;
          this.idAgendamentoSelecionado = compromisso.idAgendamento;
          this.indexCompromissoSelecionado = index;
          this.carregarTiposFormularios();
          this.$emit("paciente", { nome: compromisso.nomePaciente });
          break;
        }
      }
    },
    carregarTiposFormularios() {
      this.loadingFormularios = true;
      this.formularios = [];
      this.edited.idFormulario = "";
      const formularioService = new FormularioService();
      formularioService.getAll(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.telaCarregada = true;
              body.data.formularios.forEach((form) => {
                let formulario = [];
                (formulario["id"] = form.id),
                  (formulario["titulo"] = form.titulo);
                formulario["descricao"] = form.descricao;
                formulario["quantidade_perguntas"] = form.quantidade_perguntas;
                this.formularios.push(formulario);
              });
            }
          },
          onError: (error) => {
            console.log(error);
          },
          onEnd: () => {
            this.loadingFormularios = false;
          },
        },
        {
          procedimento: this.procedimento,
          ativo: 1,
        }
      );
    },

    async carregarTiposFormulariosPorProcedimento(procedimento) {
      this.loadingFormularios = true;
      this.formularios = [];
      const medicoId = await JSON.parse(sessionStorage.vuex).usuario.dados
        .medicoId;

      const formularioService = new FormularioService();
      formularioService.getAll(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.telaCarregada = true;
              this.formularios = body.data.formularios;
            }
          },
          onError: (error) => {
            console.log(error);
          },
          onEnd: () => {
            this.loadingFormularios = false;
          },
        },
        {
          procedimento: procedimento,
          ativo: 1,
        },
        medicoId
      );
    },

    iniciarConsulta() {
      this.$emit("iniciar-consulta", {
        idAgendamentoSelecionado: this.idAgendamentoSelecionado,
        idFormulario: this.formulario,
      });
    },

    limparSelecao() {
      this.indexCompromissoSelecionado = null;
      this.idAgendamentoSelecionado = null;
    },
    registrarPacienteAusente() {
      this.$emit("paciente-ausente", {
        idAgendamentoSelecionado: this.idAgendamentoSelecionado,
      });
    },
    selecionarAgendamento(toggle, compromisso) {
      toggle();
      this.procedimento = compromisso.idProcedimento;
      this.procedimentoAtendido = compromisso;
      this.idAgendamentoSelecionado = compromisso.idAgendamento;
      this.carregarTiposFormularios();
      this.$emit("paciente", { nome: compromisso.nomePaciente });
    },
    componenteCard(active, idConsulta) {
      return {
        "00": "CardPacientePosterior",
        "01": "CardPacienteAnterior",
        10: "CardPacienteAtual",
        11: "CardPacienteAtual",
      }[`${+active}${+!!idConsulta}`];
    },
    closeModalConsulta() {
      this.modalConsulta = false;
      this.edited.idFormulario = "";
    },
    async salaConferencia(idRequisicaoProcedimento) {
      await this.agendamentoSalaService
        .getSalaAgendamento(idRequisicaoProcedimento)
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          const url = data.urlmeeting;
          this.$emit("atualizarCompromissos");
          window.open(url, "_blank");
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {});
    },
    uploadExamesCall(item) {
      this.pacienteTelevet = {
        id_paciente: item.idPaciente,
        id_pet: item.idPet,
      };
      this.dialogUploadExame = true;
      this.forceRender();
    },
    statusText(idStatus) {
      switch (parseInt(idStatus)) {
        case 3:
          return "NÃO COMPARECEU";
        case 4:
          return "DESISTÊNCIA";
        case 5:
          return "PERDA CONEXÃO";
        case 6:
          return "INSTABILIDADE DE CONEXÃO";
        default:
          return "PENDENTE";
      }
    },
    statusStyle(idStatus) {
      switch (parseInt(idStatus)) {
        case 1:
          return "blue";
        case 0:
          return "orange";
        default:
          return "red";
      }
    },
  },
};
</script>

<style scoped>
#tabela_agenda_lista {
  width: 100%;
  float: left;
  margin-top: 74px;
}
.boxTable {
  overflow: auto;
}
.boxTable {
  margin-top: 10px !important;
  margin-bottom: 80px !important;
  border-radius: 15px;
}

#tabela_agenda_lista table {
  line-height: 22px;
  border-collapse: collapse;
}
.agenda-lista {
  font-size: 9pt !important;
}
table {
  font-size: 12px;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

#tabela_agenda_lista table {
  line-height: 22px;
  border-collapse: collapse;
}
.agenda-lista {
  font-size: 9pt !important;
}
table {
  font-size: 12px;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

.agenda-lista thead {
  font-size: 9pt;
  box-shadow: none;
}

strong {
  font-size: 12pt;
  font-weight: bold;
  color: #000000;
}

.btnSelecionarPaciente {
  color: #070060;
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
}

.btnSelecionarPaciente:hover {
  filter: brightness(0.4);
}

.tipo-proc {
  font-size: 8pt !important;
  color: #5f6f7f !important;
}

.proc {
  font-size: 12pt !important;
  color: #000 !important;
  font-weight: bold !important;
  letter-spacing: 0;
}
.table-crud thead {
  color: #5f6f7f;
  font-size: 12px;
  text-decoration: none;
  height: 40px;
  border-radius: 15px !important;
  margin: 5px !important;
}

.agenda-lista .ag-l-content .ag-l-item.ag-l-status {
  max-width: 200px;
  width: 200px;
}
.agenda-lista .ag-l-content .ag-l-status {
  border-left: solid #e3e5e8;
  border-left-width: medium;
  border-right: solid #e3e5e8;
  border-right-width: medium;
  border-width: 1px;
  color: #5f6f7f;
}
.agenda-lista .ag-l-content .ag-l-item {
  padding: 1em 1em;
  font-weight: bolder;
}
.agenda-lista #agenda-titles {
  width: 100%;
  color: #999;
  background-color: #f8fafd;
  text-align: left;
}
.agenda-lista .ag-l-content .ag-l-item:nth-of-type(1) {
  max-width: 60px;
  width: 60px;
  padding-left: 20px;
  border-radius: 6px 0 0 6px;
}
.agenda-lista .ag-l-content .ag-l-item {
  padding: 1em 1em;
  padding-left: 1em;
  font-weight: bolder;
}
.agenda-lista .ag-l-content .compromisso_agendado {
  color: #000;
}
.table-crud td:first-child,
.table-crud th:first-child {
  border-radius: 10px 0 0 10px;
}
.agenda-lista .colored-border {
  position: relative;
}
/* Erro Pdf Prontuario*/
.table-crud td {
  background-color: #fff;
  /* padding: 15px 6px; */
  align-items: center;
}
.h-row {
  min-height: 78px;
}
.color-row {
  width: 12px !important;
  min-height: 70px !important;
}
td,
th {
  padding: 0;
}

.compromisso_agendado {
  cursor: pointer;
  overflow: hidden;
  color: #fff;
}
td,
th {
  padding: 0;
}

table tr {
  border: 0;
  margin: 5px !important;
  border-radius: 20px !important;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.05) !important;
}
.agenda-lista .ag-l-content {
  border-radius: 8px !important;
}
#tabela_agenda_lista table {
  line-height: 22px;
  border-collapse: collapse;
}
.agenda-lista {
  font-size: 9pt !important;
}
table {
  font-size: 12px;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/* aqui */
table {
  border-spacing: 0;
}
.CI td {
  background: #fff;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.09);
  margin: 0 !important;
}
.spacer td {
  background: transparent !important;
  box-shadow: 0 !important;
}

tr td:first-child {
  border-top-left-radius: 10px;
}
tr td:last-child {
  border-top-right-radius: 10px;
}
tr td:first-child {
  border-bottom-left-radius: 10px;
}
tr td:last-child {
  border-bottom-right-radius: 10px;
}

.agenda-lista .ag-btn-atd {
  font-size: 8pt;
  float: right;
  border-style: solid;
  border-color: #1daf80;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center right 15%;
  text-align: left;
  background-size: 13%;
  border-radius: 200px;
  border-width: 2px;
  height: 35px;
  padding-top: 0.25rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-weight: bold;
  color: #1daf80;
}

.agenda-lista .ag-btn-atd:hover {
  color: #fff;
  background-color: #1daf80;
}
.ag-btn-enc.btn-disabled,
.btn-call.btn-disabled {
  border-color: #bdbdbd !important;
}
.agenda-lista .ag-btn-atd.btn-disabled,
.agenda-lista .ag-btn-atd.btn-disabled .ag-btn-atd:hover {
  cursor: default;
  color: #fff !important;
  background-color: #bdbdbd !important;
  border-color: #bdbdbd !important;
}

.actions {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
}
.btn-call {
  border: 2px solid #2d44a8;
  background-color: #fff;
  color: #2d44a8 !important;
}
.btn-call:hover {
  border: 2px solid white;
  background-color: #2d44a8;
  color: white !important;
}
.ag-btn-enc {
  border: 2px solid #ac3535;
  background-color: #fff;
  color: #ac3535 !important;
}
.ag-btn-enc:hover {
  border: 2px solid white;
  background-color: #ac3535;
  color: white !important;
}
</style>
