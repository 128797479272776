<template>
  <div>
    <div class="p-lg-3">
      <div class="row justify-content-between d-none d-sm-flex">
        <h3 class="mt-0 mb-2 font-weight-bold text-left col-8">Meus Pets</h3>
        <v-row justify="end">
          <v-btn
            color="#1daf80"
            dark
            class="mb-2"
            @click="dialogCadastrar = true"
          >
            Novo Pet
          </v-btn>
        </v-row>
      </div>
      <div class="d-flex d-sm-none justify-content-between flex-column mb-5">
        <h3 class="mt-0 mb-2 font-weight-bold text-center">
          Meus Pets
        </h3>
        <v-divider></v-divider>
        <v-btn
          color="#1daf80"
          dark
          class="mb-2 btn-mob"
          @click="dialogCadastrar = true"
        >
          Novo Pet
        </v-btn>
      </div>
    </div>
    <v-data-table
      :headers="headers"
      :items="petList"
      :items-per-page="5"
      class="elevation-1"
      mobile-breakpoint="350"
    >
      <template v-slot:item.actions="{ item }">
        <v-template class="d-none d-sm-block">
          <v-icon
            v-if="false"
            title="Medicina com Carinho"
            small
            class="mr-2"
            @click="medicinaCartao(item)"
          >
            mdi mdi-card-bulleted
          </v-icon>
          <v-icon
            title="Vouchers"
            small
            class="mr-2"
            @click="meusVouchers(item)"
          >
            mdi-ticket-confirmation
          </v-icon>
          <v-icon
            title="Arquivos"
            small
            class="mr-2"
            @click="uploadArquivos(item)"
          >
            mdi-folder-arrow-up-outline
          </v-icon>
          <v-icon title="Exames" small class="mr-2" @click="verArquivos(item)">
            <!-- mdi-file-upload-outline -->
            mdi-medical-bag
          </v-icon>

          <v-icon
            title="Visualizar"
            small
            class="mr-2"
            @click="editItem(item, true)"
          >
            mdi mdi-eye
          </v-icon>
          <v-icon
            title="Editar"
            small
            class="mr-2"
            @click="editItem(item, false)"
          >
            mdi-pencil
          </v-icon>
        </v-template>
        <v-template class="d-block d-sm-none">
          <div class="text-center">
            <v-btn class="mr-2" icon small dark @click="menuAcoesAction(item)">
              <v-icon small color="#858585">
                fas fa-ellipsis-v
              </v-icon>
            </v-btn>
            <!-- <v-icon small class="mr-2">
              fas fa-ellipsis-v
            </v-icon> -->
          </div>
        </v-template>

        <!-- </td> -->
        <!-- </tr> -->
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialogArquivo"
      v-if="dialogArquivo"
      fullscreen
      hide-overlay
      max-width="500px"
    >
      <UploadExames :paciente="petArquivo" @close="dialogArquivo = false" />
    </v-dialog>
    <v-dialog
      v-model="dialogUploadArquivo"
      v-if="dialogUploadArquivo"
      fullscreen
      hide-overlay
      max-width="500px"
    >
      <upload-arquivos-cliente
        :paciente="petArquivo"
        @close="dialogUploadArquivo = false"
      />
    </v-dialog>
    <v-dialog
      v-model="dialogMedicinaCartao"
      v-if="dialogMedicinaCartao"
      max-width="500px"
    >
      <dialog-cartao-medicina
        :infos="infosPet"
        @close="dialogMedicinaCartao = false"
      />
    </v-dialog>
    <v-dialog
      v-model="dialogVoucher"
      v-if="dialogVoucher"
      fullscreen
      hide-overlay
      max-width="500px"
    >
      <meus-vouchers-cliente :infos="infosPet" @close="dialogVoucher = false" />
    </v-dialog>
    <v-dialog
      fullscreen
      hide-overlay
      max-width="500px"
      v-if="dialogEdicao"
      v-model="dialogEdicao"
    >
      <modal-edicao
        v-if="dialogEdicao"
        :petId="editedPetId"
        :visualizar="visualizar"
        @close="closeDialogEdited"
        @response="
          dialogEdicao = false;
          setPets();
        "
      />
    </v-dialog>
    <v-dialog
      v-model="menuAcoes"
      class="d-flex d-sm-none"
      transition="dialog-bottom-transition"
      scrollable
    >
      <div class="menu-style">
        <h4 class="text-center my-4"><strong>Ações</strong></h4>
        <v-divider></v-divider>
        <v-list>
          <v-list-item-group>
            <!-- <v-list-item @click="medicinaCartao(itemSelecionado)">
              <v-list-item-icon>
                <v-icon>mdi mdi-card-bulleted</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  Medicina com Carinho
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item> -->
            <v-list-item
              @click="
                meusVouchers(itemSelecionado);
                menuAcoes = false;
              "
            >
              <v-list-item-icon>
                <v-icon>mdi mdi-card-bulleted</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  Vouchers
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              @click="
                uploadArquivos(itemSelecionado);
                menuAcoes = false;
              "
            >
              <v-list-item-icon>
                <v-icon>mdi-folder-arrow-up-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  Arquivos
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              @click="
                verArquivos(itemSelecionado);
                menuAcoes = false;
              "
            >
              <v-list-item-icon>
                <v-icon>mdi-medical-bag</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  Exames
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              @click="
                editItem(itemSelecionado, true);
                menuAcoes = false;
              "
            >
              <v-list-item-icon>
                <v-icon>mdi mdi-eye</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  Visualizar
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              @click="
                editItem(itemSelecionado, false);
                menuAcoes = false;
              "
            >
              <v-list-item-icon>
                <v-icon>mdi-pencil</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  Editar
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
    </v-dialog>
    <v-dialog persistent fullscreen v-model="dialogCadastrar" max-width="500px">
      <modal-cadastro
        v-if="dialogCadastrar"
        @close="closeDialogCadastrar"
        @response="atualizarPetPosCadastro"
      />
    </v-dialog>
  </div>
</template>
<script>
//import pets from ''
import Mixin from "@/mixins/vuex_snackbar_mixin.js";
import VuexUsuarioMixin from "@/mixins/vuex_usuario_mixin.js";
import PetsService from "../services/pets_service.js";
//import Historico from "@/components/historico/historico.vue";
import modalCadastro from "@/components/pets/modal_cadastro";
//import Arquivo from "@/components/prontuarios/arquivos.vue";
import UploadExames from "@/components/prontuarios/uploadExamesCliente.vue";
import uploadArquivosCliente from "@/components/prontuarios/uploadArquivosCliente.vue";
import modalEdicao from "@/components/pets/modal_edicao_cliente";
import meusVouchersCliente from "@/components/meus_vouchers/visualizar_vouchers.vue";
import DialogCartaoMedicina from "@/components/medicina_carinho/visualizar_medicina_carinho.vue";

export default {
  mixins: [Mixin, VuexUsuarioMixin],
  components: {
    // Historico,
    modalCadastro,
    modalEdicao,
    UploadExames,
    uploadArquivosCliente,
    meusVouchersCliente,
    DialogCartaoMedicina,
    //Arquivo
  },
  data: () => ({
    pets: [],
    dialogArquivo: false,
    dialogUploadArquivo: false,
    dialogVoucher: false,
    dialogMedicinaCartao: false,
    dialogEdicao: false,
    petArquivo: {},
    infosPet: {},
    dialog: false,
    editedPetId: {},
    itemSelecionado: {},
    visualizar: false,
    dialogCadastrar: false,
    menuAcoes: false,
    petList: [],
    petService: PetsService.build(),
    headers: [
      {
        text: "ID",
        align: "start",
        value: "id_pet",
      },
      { text: "Nome", align: "start", value: "nome_pet" },
      { text: "Raça", align: "start", value: "nome_raca" },
      { text: "Sexo", align: "start", sortable: false, value: "sexo" },
      { text: "Ações", align: "end", sortable: false, value: "actions" },
    ],
  }),
  mounted() {
    this.pets = this.$global.pets;
    this.setPets();
  },
  methods: {
    setPets() {
      this.petService.getPetsByCliente().then(async (response) => {
        if (response.status !== 200) return;
        const { data } = await response.json();
        const { pets } = data;
        this.petList = pets;
      });
    },
    editItem(item, bool) {
      this.visualizar = bool;
      this.editedPetId = parseInt(item.id_pet) || 0;
      this.dialogEdicao = true;
    },
    closeDialogEdited() {
      this.dialogEdicao = false;
    },

    uploadArquivos(item) {
      this.petArquivo = { id_paciente: item.id_paciente, id_pet: item.id_pet };
      this.dialogUploadArquivo = true;
    },
    meusVouchers(item) {
      this.infosPet = {
        id_tutor: item.id_paciente,
        id_pet: item.id_pet,
        nome_pet: item.nome_pet,
      };
      this.dialogVoucher = true;
    },
    medicinaCartao(item) {
      this.infosPet = {
        id_tutor: item.id_paciente,
        id_pet: item.id_pet,
        nome_pet: item.nome_pet,
      };
      this.dialogMedicinaCartao = true;
    },
    verArquivos(item) {
      this.petArquivo = { id_paciente: item.id_paciente, id_pet: item.id_pet };
      this.dialogArquivo = true;
    },
    closeDialogCadastrar() {
      this.dialogCadastrar = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    agendamento() {
      this.$router.push("agendamento-cliente");
    },
    listHistorico() {
      this.dialog = true;
    },
    redirectTo(path) {
      this.$router.push(path);
    },
    close() {
      this.dialog = false;
    },
    atualizarPetPosCadastro() {
      this.setPets();
      this.closeDialogCadastrar();
    },
    menuAcoesAction(item) {
      this.itemSelecionado = item;
      this.menuAcoes = true;
    },
  },
};
</script>
<style scoped>
.v-application .text-start {
  text-align: center !important;
}

.v-btn {
  color: white !important;
}
.btn-mob {
  border-radius: 20px;
}
.menu-style {
  background-color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
@media (max-width: 575.98px) {
}
</style>
