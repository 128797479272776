<template>
  <div class="container">
    <div class="row justify-content-center mt-8">
      <div class="col-10">
        <div v-for="value in infos" :key="value.index" class="list_style">
          <strong>{{ value.desc }}: </strong>
          {{ value.info }}
        </div>
        <div class="list_style pb-5">
          <h4>Produtos:</h4>
          <hr />
          <div class="row justify-content-between">
            <div class="col-2" style="border-right: 1px solid #f7f7f7">
              <strong>ID</strong>
            </div>
            <div class="col-3" style="border-right: 1px solid #f7f7f7">
              <strong>Nome</strong>
            </div>
            <div class="col-2" style="border-right: 1px solid #f7f7f7">
              <strong>Quantidade</strong>
            </div>
            <div class="col-2" style="border-right: 1px solid #f7f7f7">
              <strong>Unidade</strong>
            </div>
            <div class="col-2">
              <strong>Total</strong>
            </div>
          </div>
          <div v-for="value in infosProdutos" :key="value.index">
            <div class="row justify-content-between">
              <div class="col-2" style="border-right: 1px solid #f7f7f7">
                {{ value.id }}
              </div>
              <div class="col-3" style="border-right: 1px solid #f7f7f7">
                {{ value.nome_produto }}
              </div>
              <div class="col-2" style="border-right: 1px solid #f7f7f7">
                {{ value.quantidade_produto }}
              </div>
              <div class="col-2" style="border-right: 1px solid #f7f7f7">
                {{ value.valor_unidade_formatado_produto }}
              </div>
              <div class="col-2">
                {{ value.valor_formatado_produto }}
              </div>
            </div>
          </div>
        </div>
        <div class="list_style pb-5">
          <h4>Pets:</h4>
          <hr />
          <div class="row">
            <div class="col-2" style="border-right: 1px solid #f7f7f7">
              <strong>ID</strong>
            </div>
            <div class="col-10">
              <strong>Nome</strong>
            </div>
          </div>
          <div v-for="value in infosPets" :key="value.index">
            <div class="row">
              <div class="col-2" style="border-right: 1px solid #f7f7f7">
                {{ value.id }}
              </div>
              <div class="col-10">
                {{ value.nome_pet }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";

export default {
  mixins: [Mixin],
  props: {
    compra: {
      type: Object,
      required: true,
    },
    detalhe: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      infos: [],
      infosPets: [],
      infosProdutos: [],
      comprasKey: [
        { key: "nome_comprador", desc: "Nome Comprador" },
        { key: "data_requisicao_formatada", desc: "Data" },
        { key: "unidade", desc: "Unidade" },
        { key: "desc_status_compra", desc: "Status Compra" },
        { key: "tipo_compra", desc: "Forma Pagamento" },
        { key: "valor_formatado", desc: "Valor" },
      ],
      detalhesKey: [
        // { key: "nome", desc: "Produto" },
        // { key: "quantidade", desc: "Quantidade" },
        // { key: "valor_unidade", desc: "Valor Produto" },
        // { key: "valor", desc: "Valor Pago" },
      ],
    };
  },
  mounted() {
    this.montarInfos();
  },
  methods: {
    montarInfos() {
      this.infos = [];
      for (let index = 0; index < this.comprasKey.length; index++) {
        const element = this.comprasKey[index];
        let valor = this.compra[element.key].toLowerCase();
        this.infos.push({
          index: this.infos.length,
          desc: element.desc,
          info: valor,
        });
      }
      for (let index = 0; index < this.detalhesKey.length; index++) {
        const element = this.detalhesKey[index];
        let valor = this.detalhe.produtos[0][element.key].toLowerCase();
        if (element.key == "valor_unidade" || element.key == "valor") {
          valor = parseFloat(valor).toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          });
        }
        this.infos.push({
          index: this.infos.length,
          desc: element.desc,
          info: valor,
        });
      }
      for (let index = 0; index < this.detalhe.produtos.length; index++) {
        const element = this.detalhe.produtos[index];
        this.infosProdutos.push({
          id: element.id,
          nome_produto: element.nome,
          quantidade_produto: element.quantidade,
          valor_unidade_produto: element.valor_unidade,
          valor_unidade_formatado_produto: parseFloat(
            element.valor_unidade
          ).toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          }),
          valor_produto: element.valor,
          valor_formatado_produto: parseFloat(element.valor).toLocaleString(
            "pt-br",
            {
              style: "currency",
              currency: "BRL",
            }
          ),
        });
      }
      for (let index = 0; index < this.detalhe.pets.length; index++) {
        const element = this.detalhe.pets[index];
        this.infosPets.push({
          id: element.id_pet,
          nome_pet: element.nome,
        });
      }
    },
  },
};
</script>

<style scoped>
.list_style {
  text-align: start;
  font-size: 1.15rem;
  text-transform: capitalize;
  margin: 15px 0px;
  padding: 10px 20px;
  color: rgb(77, 77, 77);
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
</style>
