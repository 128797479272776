<template>
  <div>
    <!-- LISTAGEM DE HORÁRIOS -->
    <!-- filtro -->
    <!-- <div class="my-2">
      <v-row v-show="false" justify="center">
        <v-expansion-panels focusable>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h5>Filtrar</h5>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <Filtro
                @aplicarFiltro="atualizarAgendamentos($event)"
                @limparFiltro="atualizarAgendamentos(null)"
                :medicos="medicos"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </div> -->
    <div class="p-lg-3">
      <div class="row justify-content-between">
        <h3 class="mt-0 mb-2 font-weight-bold text-left">
          <!-- Agenda TeleConsulta -->
          Agenda Teleorientação
        </h3>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-12 d-flex justify-content-end">
        <!-- BOTÃO QUE ACIONA MODAL DE CRIAÇÃO DE FAIXA DE HORÁRIOS -->
        <v-btn
          v-if="allowCriarAgendaBtn"
          class="btn-app-primary mr-2"
          @click="dialogCriacaoFaixaHorarios = true"
        >
          <span>Criar Agenda</span>
        </v-btn>
        <!-- BOTÃO QUE ACIONA MODAL DE CRIAÇÃO DE HORÁRIO INDIVIDUAL -->
        <v-btn
          v-if="allowCriarHorarioBtn"
          class="btn-app-primary"
          style="background-color: rgb(5, 173, 119) !important"
          @click="dialogCriacaoHorarioIndividual = true"
        >
          <span>Criar horário</span>
        </v-btn>
      </div>
    </div>
    <v-divider></v-divider>
    <div class="row">
      <div class="col-12 d-flex justify-content-center">
        <v-select
          class="col-3 mt-4"
          label="Especialidades"
          style="padding-top: 0 !important"
          :items="listaEspecialidades"
          v-model="especialidadeId"
        />
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="datas"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              class="col-5"
              v-model="computedFormatDate"
              label="Data inicial - Data final"
              prepend-icon="mdi-calendar"
              clearable
              v-bind="attrs"
              v-on="on"
              @click:clear="datas = null"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="datas"
            no-title
            range
            scrollable
            color="green darken-4"
          >
            <v-spacer></v-spacer>
            <v-btn text @click="menu = false"> Cancelar </v-btn>
            <v-btn dark color="success" @click="$refs.menu.save(datas)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
        <v-btn
          class="ml-2 mt-3"
          outlined
          color="#1daf80"
          :disabled="disablePesquisa"
          @click="pesquisaAgenda"
        >
          buscar
          <v-icon class="ml-2" small> mdi-magnify </v-icon>
        </v-btn>
      </div>
    </div>

    <v-data-table
      @update:sort-desc="ordenar"
      :footer-props="{ 'items-per-page-options': [5, 10, 20, 50, 100] }"
      :search="search"
      :headers="dataTableHeaders"
      :items="horarios"
      :loading="loading"
      :options.sync="options"
      :server-items-length="pagination.num_rows"
      :loading-text="'Carregando...'"
      class="elevation-1"
      v-if="primeiraPesquisa"
    >
      <template v-slot:top>
        <!-- BARRA SUPERIOR -->
      </template>
      <!-- COLUNA DE AÇÕES -->
      <template v-slot:item.actions="{ item }">
        <!-- BOTÃO (LÁPIS DE EDIÇÃO) QUE HABILITA MODAL DE EDIÇÃO DE HORÁRIO -->
        <!-- <v-icon
          v-if="allowEditarHorarioBtn"
          small
          class="mr-2"
          @click="selectHorarioForEdition(item)"
        >
          mdi-pencil
        </v-icon> -->
        <!-- BOTÃO (LIXEIRA) QUE HABILITA MODAL DE EXCLUSÃO DE HORÁRIO -->
        <v-icon
          v-if="item.status !== 'AGENDADO' && allowDeletarHorarioBtn"
          small
          @click="selectHorarioForDeletion(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <!-- BOTÃO PARA ATUALIZAR LISTA DE HORÁRIOS QUANDO A API NÃO DEVOLVER OS DADOS DE AGENDA -->
      <template v-slot:no-data>
        <p class="text-center">Nenhum registro encontrado</p>
        <v-btn color="#1daf80" dark @click="atualizarAgendamentos">
          Atualizar
        </v-btn>
      </template>
    </v-data-table>
    <!-- DIALOG DE CADASTRO DE FAIXA DE HORÁRIOS -->
    <v-dialog v-model="dialogCriacaoFaixaHorarios" max-width="70%">
      <v-card class="p-lg-3">
        <v-card-title>
          <div class="p-lg-3">
            <div class="row justify-content-between">
              <h3 class="mt-0 mb-2 font-weight-bold text-left">
                Cadastro de Horários
              </h3>
            </div>
          </div>
        </v-card-title>
        <modal-criar-agenda
          v-if="dialogCriacaoFaixaHorarios"
          @response="finishFaixaHorariosCadastro"
        />
      </v-card>
    </v-dialog>

    <!-- DIALOG DE CADASTRO DE HORÁRIO INDIVIDUAL -->
    <v-dialog v-model="dialogCriacaoHorarioIndividual">
      <v-card class="p-lg-3">
        <v-card-title>
          <div class="p-lg-3">
            <div class="row justify-content-between">
              <h3 class="mt-0 mb-2 font-weight-bold text-left">
                Cadastro de Horário
              </h3>
            </div>
          </div>
        </v-card-title>
        <modal-criar-horario
          v-if="dialogCriacaoHorarioIndividual"
          @response="finishHorarioIndividualCadastro"
        />
      </v-card>
    </v-dialog>

    <!-- DIALOG EDIÇÃO DE HORÁRIO -->
    <v-dialog
      v-if="dialogEdicaoHorario"
      v-model="dialogEdicaoHorario"
      max-width="600px"
    >
      <modal-editar-procedimento
        v-if="dialogEdicaoHorario"
        :horario="horario"
        @close="dialogEdicaoHorario = false"
        @response="finishHorarioEdition"
      />
    </v-dialog>

    <!-- DIALOG DE EXCLUSÃO DE HORÁRIO -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <modal-excluir-horario
        v-if="dialogDelete"
        :horario="horario"
        @close="dialogDelete = false"
        @response="finishHorarioDeletion"
      />
    </v-dialog>
  </div>
</template>

<script>
import VuexSnackbarMixin from "@/mixins/vuex_snackbar_mixin.js";
import AgendaService from "@/services/agenda_service.js";
import ModalCriarAgenda from "@/components/agendamento_medico/modal_criar_agenda.vue";
import ModalCriarHorario from "@/components/agendamento_medico/modal_criar_horario.vue";
import ModalEditarProcedimento from "@/components/agendamento_medico/modal_editar_procedimento.vue";
import ModalExcluirHorario from "@/components/agendamento_medico/modal_excluir_horario.vue";
// import Filtro from "@/components/agendamento_medico/filtro.vue";
import AuthService from "../services/auth_service";
import endpoints from "../router/endpoints";
import MedicoService from "../services/medico_service";
import EspecialidadeService from "@/services/especialidade_service.js";

export default {
  mixins: [VuexSnackbarMixin],
  components: {
    ModalCriarHorario,
    // Filtro,
    ModalCriarAgenda,
    ModalEditarProcedimento,
    ModalExcluirHorario,
  },

  data: () => ({
    filtro: {},
    sort: "",
    sorting: {},
    horarios: [], // Guarda o array de horários que alimenta a tabela.
    horario: {}, // Guarda os dados do horário escolhido quando alguma ação for realizada.
    dialogCriacaoHorarioIndividual: false,
    dialogCriacaoFaixaHorarios: false,
    dialogEdicaoHorario: false,
    dialogDelete: false,
    pagination: {
      num_rows: 0,
      current_page: 0,
      first_page: 0,
      last_page: 0,
      previous_page: 0,
      next_page: 0,
      per_page: 0,
    },
    loading: false, // Guarda o estado do loading de tabela quando a lista de horários está sendo carregada.
    dataTableHeaders: [
      { text: "Médico", value: "nomeMedico", align: "start", sortable: false },
      {
        text: "Especialidade",
        value: "especialidadeDescricao",
        align: "start",
        sortable: false,
      },
      { text: "Horário início", value: "horarioInicio", sortable: false },
      { text: "Horário final", value: "horarioFinal", sortable: false },
      { text: "Data", value: "date", sortable: false },
      // { text: "Status", value: "status", sortable: false },
      { text: "Ações", value: "actions", sortable: false, align: "center" },
    ],
    search: "", // Guarda a variável usada para fazer pesquisa na lista de horários.
    options: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      ordenacao: false,
      desc: false,
    },
    services: {
      authService: AuthService.build(),
      medicoService: MedicoService.build(),
      especialidadeService: EspecialidadeService.build(),
      agendaService: AgendaService.build(),
    },
    medicos: [],
    primeiraPesquisa: false,
    datas: [],
    listaEspecialidades: [],
    especialidadeId: null,
    menu: false,
    disablePesquisa: true,
  }),
  async mounted() {
    // const unidadeId = ~~JSON.parse(sessionStorage.vuex).unidade?.id;
    // this.medicos = await this.services.medicoService.getMedicoListByUnidadeId(
    //   unidadeId
    // );
    // this.atualizarAgendamentos();
    await this.getEspecialidades();
  },
  watch: {
    options: {
      handler() {
        // this.options.desc = this.options.sortDesc[0] ?? false
        this.pesquisaAgenda();
      },
      deep: true,
    },
    search() {
      this.atualizarAgendamentos();
    },
    datas() {
      if (this.datas.length == 2) {
        this.validarPesquisa();
      }
    },
    especialidadeId() {
      this.validarPesquisa();
    },
  },
  computed: {
    computedFormatDate() {
      const datasFormatadas =
        this.datas &&
        this.datas.map((item) =>
          item
            .split("-")
            .reverse()
            .join("/")
        );
      return datasFormatadas;
    },
    allowCriarAgendaBtn() {
      return this.services.authService.temPermissao(
        endpoints["AgendaController::POST->registerMultipleAgenda/$1"]
      );
    },
    allowCriarHorarioBtn() {
      return this.services.authService.temPermissao(
        endpoints[
          "AgendaEspecialidadeController::POST->registerAgendaEspecialidade"
        ]
      );
    },
    allowEditarHorarioBtn() {
      return this.services.authService.temPermissao(
        endpoints[
          "AgendaEspecialidadeController::POST->updateAgendaEspecialidade/$1"
        ]
      );
    },
    allowDeletarHorarioBtn() {
      return this.services.authService.temPermissao(
        endpoints[
          "AgendaEspecialidadeController::POST->deleteAgendaEspecialidade/$1"
        ]
      );
    },
  },
  methods: {
    validarPesquisa() {
      if (this.datas.length == 2 && this.especialidadeId != null) {
        this.disablePesquisa = false;
      }
    },
    async pesquisaAgenda() {
      let teste = this.datas;
      teste = teste.sort();
      let dataInicial = teste[0].replaceAll("-", "");
      let dataFinal = teste[1].replaceAll("-", "");

      this.primeiraPesquisa = true;
      await this.services.agendaService
        .getAgendaByEspecialidadeUnidadePeriodo(
          this.especialidadeId,
          24,
          dataInicial,
          dataFinal,
          this.getPaginationConsultaParams()
        )
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          this.horarios = [];
          this.pagination = data.paginacao;

          data.dados.forEach((horario) => {
            let dataArr = horario.data.split("-");
            const horarioAgenda = {
              id: horario.id,
              especialidadeDescricao: horario.nome_especialidade ?? "",
              especialidadeId: horario.id_especialidade,
              horarioInicio: horario.hora_inicio,
              horarioFinal: horario.hora_final,
              date: dataArr[2] + "/" + dataArr[1] + "/" + dataArr[0],
              unidadeId: horario.id_unidade,
              nomeMedico: horario.nome,
            };
            this.horarios.push(horarioAgenda);
          });
        });
    },
    getPaginationConsultaParams() {
      return {
        page: this.options.page,
        per_page: this.options.itemsPerPage,
      };
    },
    selectHorarioForEdition(item) {
      this.horario = JSON.parse(JSON.stringify(item));
      this.dialogEdicaoHorario = true;
    },
    selectHorarioForDeletion(item) {
      this.horario = JSON.parse(JSON.stringify(item));
      this.dialogDelete = true;
    },
    finishHorarioIndividualCadastro() {
      this.dialogCriacaoHorarioIndividual = false;
      if (this.disablePesquisa == false) {
        this.pesquisaAgenda();
      }
    },
    finishFaixaHorariosCadastro() {
      this.dialogCriacaoFaixaHorarios = false;
      if (this.disablePesquisa == false) {
        this.pesquisaAgenda();
      }
    },
    finishHorarioEdition() {
      this.dialogEdicaoHorario = false;
      if (this.disablePesquisa == false) {
        this.pesquisaAgenda();
      }
    },
    finishHorarioDeletion() {
      this.dialogDelete = false;
      if (this.disablePesquisa == false) {
        this.pesquisaAgenda();
      }
    },
    ordenar(sorting) {
      if (sorting[0] === false) {
        console.log("desc");
        this.sort = "-";
      } else if (sorting[0] === true) {
        console.log("asc");
        this.sort = "";
      }
    },
    async getEspecialidades() {
      await this.services.especialidadeService
        // .getEspecialidadeList()
        .getEspecialidadeListByUnidadeId(
          JSON.parse(sessionStorage.vuex).unidade.id
        )
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          this.listaEspecialidades = data.map((pet) => {
            return {
              text: pet.descricao,
              value: pet.id_especialidade,
            };
          });
        });
    },
    atualizarAgendamentos(filtro = {}) {
      let filtroAplicado = filtro ? JSON.parse(JSON.stringify(filtro)) : {};
      if (filtro && JSON.stringify(filtro) === "{}") {
        filtroAplicado = this.filtro;
      } else if (filtro) {
        this.filtro = filtro;
      } else if (filtro === null) {
        filtroAplicado = {};
        this.filtro = {};
      }
      filtroAplicado.sort = "-";
      const agendaService = new AgendaService();
      // const idMedico = filtroAplicado.medico_id || 0;
      // console.log({filtroAplicado, idMedico})
      // if(!idMedico) {
      //   return;
      // }
      this.loading = true;
      this.horarios = [];
      agendaService.getAgendaMedicoEspecialidade(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.horarios = [];
              this.pagination = body.data.pagination;
              body.data.agendas.forEach((horario) => {
                let dataArr = horario.data.split("-");
                const horarioAgenda = {
                  id: horario.id,
                  especialidadeDescricao: horario.nome_especialidade ?? "",
                  especialidadeId: horario.id_especialidade,
                  tipoProcedimento: horario.tipo_procedimento,
                  horarioInicio: horario.hora_inicio,
                  horarioFinal: horario.hora_final,
                  date: dataArr[2] + "/" + dataArr[1] + "/" + dataArr[0],
                  status: horario.id_agendamento ? "AGENDADO" : "DISPONÍVEL",
                  unidadeId: ~~horario.id_unidade,
                  clienteNome: horario.nome_cliente || "",
                  petNome: horario.nome_pet || "",
                  medicoId: ~~horario.id_medico,
                  medicoNome: (() => {
                    const medico = this.medicos.find(
                      ({ medicoId }) => ~~horario.id_medico === medicoId
                    );
                    return medico ? medico.nome : "";
                  })(),
                };
                this.horarios.push(horarioAgenda);
              });
            } else {
              console.error("error");
            }
          },
          onError: () => {
            this.$_ACTIONS_showSnackbarMessage({
              message: this.$global.messages.internalServerError,
              color: "error",
            });
          },
          onEnd: () => {
            this.loading = false;
          },
        },
        this.options,
        filtroAplicado
      );
    },
  },
};
</script>

<style scoped>
.btn-app-primary {
  background-color: rgb(19, 125, 91) !important;
  border-color: rgb(19, 125, 91);
  color: white;
}
.form-group label {
  text-align: left !important;
  display: block;
}
</style>
