const urlDev = "http://localhost";

// const base = "Homolog";
// const base = "Dev";

const base = "Prod";
const url = selecionarBase(base);

const global = {
  visaoCliente: true,
  // visaoCliente: false,

  tokenK2:
    "EAAD8Wjfi4VgBO12K8ewaDqCyEeWL6DfFmeblvypIXVEBWDnW2iFrPTGGlh7ZBlHWpKWZCrHWcT8kABq2sytDT8FrAG8cjEua3bXK3DrxWJzeegvE6nlVFLZACwFbNU5eOdkZBeNk1w6JQhgS9vVGmodOv0iZBHtkdDI6MaDfsv0Hlzqw6HB61jFKcMLW7ahB8SXAZA42lPWk7T1T9W",
  api: {
    baseURLK2:
      "https://anclivepa.placein.com.br/app.allowhats_apoio&assunto=sendMessage",
    baseURL: url.baseURL,
    baseURLVetus: url.baseURLVetus,
    baseUrlChamefacil: url.baseUrlChamefacil,
    baseUrlChamefacil_api: url.baseUrlChamefacil_api,
    token_pagSeguro: url.token_pagSeguro,
  },
  consultas: {
    tempoMinimo: 1,
    idTipoProcedimentoConsulta: 5,
  },
  tiposProcedimentos: {
    //consulta: 5,
    //cirurgia: 6,
    consulta: 2,
    cirurgia: 3,
    compromissoPessoal: 7,
  },
  utils: {
    diasDaSemana: [
      { value: 0, label: "Domingo" },
      { value: 1, label: "Segunda-feira" },
      { value: 2, label: "Terça-feira" },
      { value: 3, label: "Quarta-feira" },
      { value: 4, label: "Quinta-feira" },
      { value: 5, label: "Sexta-feira" },
      { value: 6, label: "Sábado" },
    ],
  },
  profiles: [
    { id: 1, description: "admin" },
    { id: 2, description: "medico" },
    { id: 3, description: "assistente" },
    { id: 4, description: "paciente" },
    { id: 56, description: "recepcao" },
  ],
  snackbar: {
    colors: {
      sucess: "#3E682A",
      error: "error",
      warning: "#FFCA28",
    },
  },
  messages: {
    internalServerError:
      "Ocorreu um erro durante o processamento da sua solicitação. Tente novamente dentro de alguns minutos. Se o serviço continuar sem funcionar, contate o administrador do sistema.",
  },
};

function selecionarBase(base) {
  let baseURL = urlDev + "/pettree_api/public";
  let baseURLVetus = urlDev + "/deskvet_dev/api";

  let baseUrlChamefacil = "http://chamefacilpettreeteste.prolins.com.br/api";
  let baseUrlChamefacil_api =
    "http://chamefacilpettreeapiteste.prolins.com.br/api";

  let token_pagSeguro = "B152E5B2DDBE46E6837995BAE27DE8DD";

  switch (base) {
    case "Prod":
      // Api Pettree
      baseURL = "https://api.deskvet.pettree.com.br/v1/public";
      baseURLVetus =
        "https://api.deskvet.homolog.prolins.com.br/vet1/public/api";

      // Api ChameFacil
      baseUrlChamefacil_api =
        "https://chamefacilpettreeapi2.prolins.com.br/api";
      baseUrlChamefacil = "https://chamefacilpettree.prolins.com.br/api";

      break;

    case "Homolog":
      // Api Pettree
      baseURL = "https://api.deskvet.homolog.prolins.com.br/v1/public";
      baseURLVetus =
        "https://api.deskvet.homolog.prolins.com.br/vet1/public/api";
      break;
    // case "Homolog":
    //   // Api Pettree
    //   baseURL = "https://pettreeapi.pv.homolog.prolins.com.br/v1/public";
    //   baseURLVetus =
    //     "https://pettreeapi.pv.homolog.prolins.com.br/vet1/public/api";
    //   break;
  }

  return {
    baseURL,
    baseURLVetus,
    baseUrlChamefacil,
    baseUrlChamefacil_api,
    token_pagSeguro,
  };
}
export { global };
