<template>
  <div>
    <div class="p-lg-3">
      <div class="row">
        <div class="col-12 title-style">
          <h3>
            Histórico Consultas
          </h3>
        </div>
      </div>
    </div>
    <v-template v-if="mobile" class="d-block d-sm-none">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="#788dfb " dark v-bind="attrs" v-on="on">
            <v-icon class="mr-3">mdi-information</v-icon>
            Legenda
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(legenda, index) in legendas" :key="index">
            <v-list-item-title>
              <div class="d-flex align-item-center">
                <div
                  style="margin: 0px 10px"
                  :class="`dot-legenda ${legenda.dot}`"
                >
                  {{ legenda.number }}
                </div>
                <span :class="`style-text ${legenda.font}`">
                  {{ legenda.descricao }}
                </span>
              </div>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <!-- <div class="row justify-content-around">
        <div class="d-flex">
          <div class="dot-legenda dot-atendido"></div>
          <span class="color-style-atendido">
            Atendido
          </span>
        </div>
        <div class="d-flex">
          <div class="dot-legenda dot-agendado"></div>
          <span class="color-style-agendado">
            Agendado
          </span>
        </div>
        <div class="d-flex">
          <div class="dot-legenda dot-cancelado"></div>
          <span class="color-style-cancelado">
            Cancelado
          </span>
        </div>
      </div> -->
    </v-template>
    <v-data-table
      v-on:update:sort-by="options.ordenacao = true"
      :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
      :search="search"
      :headers="headers"
      :items="consultas"
      :options.sync="options"
      :server-items-length="totalList"
      class="elevation-1 mb-2 mt-5"
      :loading="loading"
      :loading-text="'Carregando...'"
      mobile-breakpoint="350"
    >
      <!-- <template v-slot:top> -->
      <!-- <v-toolbar flat> -->
      <!-- DIALOG DE CADASTRO -->
      <!-- <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Pesquisar pelo nome do pet"
                single-line
                hide-details
              />
            </v-toolbar> -->
      <!-- </template> -->
      <template v-slot:item.status="{ item }">
        <v-template class="d-none d-sm-block">
          {{ item.idConsulta ? "Atendido" : "Agendado" }}
        </v-template>
        <v-template class="d-block d-sm-none">
          <div class="dot-legenda" :class="tipoDotAgendamento(item.idConsulta)">
            {{ numberDotConsulta(null, item.idConsulta) }}
          </div>
        </v-template>
      </template>
      <!-- <template v-slot:item.status_sala="{ item }">
        <v-template class="d-none d-sm-block">
          {{
            item.status_sala
              ? item.status_sala
              : item.idConsulta
              ? "Atendido"
              : "Agendado"
          }}
        </v-template>
        <v-template class="d-block d-sm-none">
          <div
            class="dot-legenda"
            :class="tipoDotConsulta(item.status_sala, item.idConsulta)"
          >
            {{ numberDotConsulta(item.status_sala, item.idConsulta) }}
          </div>
        </v-template>
      </template> -->
      <template v-slot:item.actions="{ item }">
        <div v-if="!item.isCancelado">
          <div class="col-6 text-left">
            <button
              v-if="item.isConfirmado"
              class="btn btn-app-primary btn-rounded font-weight-bold"
            >
              <span> Acessar </span>
            </button>
          </div>
          <v-row v-if="!item.isConfirmado" class="mb-2">
            <!--            <div class="col-3 text-left mx-5">-->
            <!--              <button class="btn btn-app-primary btn-rounded font-weight-bold">-->
            <!--                <span> Reagendar </span>-->
            <!--              </button>-->
            <!--            </div>-->

            <button
              @click="showCancelarConsulta(item)"
              class="btn btn-app-primary btn-rounded font-weight-bold"
              v-if="item.isCancelado"
            >
              {{ item.isCancelado }}
              <span> Cancelar </span>
            </button>
            <v-dialog v-model="dialogCancelarConsulta" max-width="500px">
              <v-card>
                <div v-if="!loadingCancelar">
                  <v-card-title class="headline" style="word-break: normal"
                    >Tem certeza de que deseja desmarcar a consulta?
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#1daf80" text @click="closeCancelarConsulta">
                      Não
                    </v-btn>
                    <v-btn
                      class="btn-danger"
                      text
                      style="color: #fff !important"
                      @click="cancelarConsulta"
                    >
                      Sim
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </div>
                <v-card-actions v-else>
                  <v-spacer></v-spacer>
                  <v-spacer></v-spacer>
                  <v-row>
                    <v-progress-circular
                      class="py-5 my-5"
                      :size="70"
                      :width="5"
                      indeterminate
                      color="green"
                    ></v-progress-circular>
                  </v-row>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <button
              @click="getMeeting(item)"
              class="btn btn-app-primary btn-rounded font-weight-bold m-0"
              v-if="item.statusMeeting != null"
            >
              <span> Acessar Meeting </span>
            </button>
          </v-row>
        </div>
      </template>
      <template v-slot:no-data>
        <p class="text-center">Nenhum registro encontrado</p>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Mixin from "@/mixins/vuex_snackbar_mixin.js";
import VuexUsuarioMixin from "@/mixins/vuex_usuario_mixin.js";
import MinhasConsultasService from "@/services/minhas_consultas_service.js";

export default {
  mixins: [Mixin, VuexUsuarioMixin],
  components: {},
  data: () => ({
    loadingCancelar: false,
    consultaCancelar: {},
    dialogCancelarConsulta: false,
    search: "",
    options: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      order: false,
      desc: false,
    },
    totalList: 10,
    consultas: [],
    headers: [
      {
        text: "data",
        align: "start",
        sortable: true,
        value: "data",
      },
      { text: "Pet", value: "pet", sortable: false },
      { text: "Especialidade", value: "especialidade", sortable: false },
      { text: "Unidade", value: "unidade", sortable: false },
      { text: "Status", value: "status", sortable: false },
      // { text: "Status Consulta", value: "status_sala", sortable: false },
      // { text: "", align: "end", value: "actions", sortable: false },
    ],
    legendas: [
      {
        number: "1",
        dot: "dot-atendido",
        descricao: "Atendido",
        font: "color-style-atendido",
      },
      {
        number: "2",
        dot: "dot-agendado",
        descricao: "Agendado",
        font: "color-style-agendado",
      },
      {
        number: "3",
        dot: "dot-cancelado",
        descricao: "Cancelado",
        font: "color-style-cancelado",
      },
      // {
      //   number: "3",
      //   dot: "dot-pendente",
      //   descricao: "Pendente",
      //   font: "color-style-pendente",
      // },
      // {
      //   number: "4",
      //   dot: "dot-sala-aberta",
      //   descricao: "Sala Aberta",
      //   font: "color-style-sala-aberta",
      // },
      // {
      //   number: "5",
      //   dot: "dot-concluido",
      //   descricao: "Concluido",
      //   font: "color-style-concluido",
      // },
      // {
      //   number: "6",
      //   dot: "dot-nao-compareceu",
      //   descricao: "Não Compareceu",
      //   font: "color-style-nao-compareceu",
      // },
      // {
      //   number: "7",
      //   dot: "dot-desistencia",
      //   descricao: "Desistência",
      //   font: "color-style-desistencia",
      // },
      // {
      //   number: "8",
      //   dot: "dot-perdeu-conexao",
      //   descricao: "Tutor perdeu a conexão",
      //   font: "color-style-perdeu-conexao",
      // },
      // {
      //   number: "9",
      //   dot: "dot-inst-conexao",
      //   descricao: "Instabilidade de conexão",
      //   font: "color-style-inst-conexao",
      // },
    ],
    loading: false,
    mobile: false,
    minhasConsultasService: MinhasConsultasService.build(),
  }),

  watch: {
    search() {},
    options: {
      handler() {
        // this.options.desc = this.options.sortDesc[0] ?? false
        this.setMinhaConsulta();
      },
      deep: true,
    },
  },
  beforeCreate() {},
  mounted() {
    this.setMinhaConsulta();
    window.addEventListener("resize", this.verificarLarguraTela);
    this.verificarLarguraTela();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.verificarLarguraTela);
  },
  computed: {},
  methods: {
    async setMinhaConsulta() {
      this.loading = true;
      await this.minhasConsultasService
        .getMinhasConsultas(this.getPacienteId(), this.getPaginationParams())
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          const { pagination, consultas } = data;
          this.totalList = pagination.num_rows;
          this.consultas = consultas.map((consulta) =>
            this.mapConsulta(consulta)
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getPacienteId() {
      return this.$_GETTERS_usuario.id;
    },
    mapConsulta(consulta) {
      return {
        data: new Date(
          consulta.data + " " + consulta.hora_inicio
        ).toLocaleString("pt-BR"),
        pet: consulta.nome_pet,
        especialidade: consulta.procedimento,
        isConfirmado: false,
        idConsulta: consulta.id_consulta,
        status: consulta.status ?? "Agendado",
        status_sala: consulta.status_sala,
        isCancelado: false, // consulta.status === "cancelado",
        agendamentoId: consulta.id_agendamento,
        unidade: consulta.unidade_descricao ?? "Não informado",
        statusMeeting: consulta.status_meeting,
      };
    },
    getPaginationParams() {
      return {
        page: this.options.page,
        per_page: this.options.itemsPerPage,
        sort: this.options.order,
        desc: this.options.desc,
        search: this.search,
      };
    },
    showCancelarConsulta(consulta) {
      this.dialogCancelarConsulta = true;
      this.consultaCancelar = consulta;
    },
    closeCancelarConsulta() {
      this.dialogCancelarConsulta = false;
    },
    async cancelarConsulta() {
      this.loadingCancelar = true;
      await this.minhasConsultasService
        .cancelarConsulta(this.consultaCancelar.agendamentoId)
        .then(async (response) => {
          if (response.status !== 200) this.showMensagemErro();
          const { data } = await response.json();
          this.$_ACTIONS_showSnackbarMessage({
            text: data.message,
            color: "success",
          });
          this.setMinhaConsulta();
        })
        .finally(() => {
          this.loadingCancelar = false;
          this.closeCancelarConsulta();
        });
    },
    showMensagemErro() {
      this.$_ACTIONS_showSnackbarMessage({
        message: "Erro ao desmarcar consulta.",
        color: "error",
      });
    },
    tipoDotAgendamento(idConsulta) {
      if (idConsulta > 0) {
        return "dot-atendido";
      } else {
        return "dot-agendado";
      }
    },
    numberDotConsulta(consulta, idConsulta) {
      let indexLegenda = -1;
      if (consulta) {
        indexLegenda = this.legendas.findIndex((ele) => {
          return (
            ele.descricao.toLowerCase() ==
            consulta.replaceAll(".", "").toLowerCase()
          );
        });
      }
      if (indexLegenda < 0) {
        indexLegenda = this.legendas.findIndex((ele) => {
          const consultaInfo = idConsulta > 0 ? "Atendido" : "Agendado";
          return ele.descricao.toLowerCase() == consultaInfo.toLowerCase();
        });
      }
      return this.legendas[indexLegenda].number;
    },
    tipoDotConsulta(consulta, idConsulta) {
      switch (consulta) {
        case "Pendente.":
          return "dot-pendente";
        case "Sala Aberta.":
          return "dot-sala-aberta";
        case "Concluido.":
          return "dot-concluido";
        case "Não Compareceu.":
          return "dot-nao-compareceu";
        case "Desistência.":
          return "dot-desistencia";
        case "Tutor perdeu a conexão.":
          return "dot-perdeu-conexao";
        case "Instabilidade de conexão.":
          return "dot-inst-conexao";
        default:
          if (idConsulta > 0) {
            return "dot-atendido";
          } else {
            return "dot-agendado";
          }
      }
    },
    verificarLarguraTela() {
      const larguraTela = window.innerWidth;
      if (larguraTela >= 576) {
        this.mobile = false;
      } else {
        this.mobile = true;
      }
    },
  },
};
</script>
<style scoped>
.btn-app-aguardo {
  background-color: #f0a211 !important;
  color: black !important;
}
.title-style h3 {
  margin: 10px 0px 20px 0px;
  font-weight: bold;
  text-align: left;
  color: #444444;
}
.dot {
  width: 20px;
  height: 20px;
  margin: auto;
  border-radius: 10px;
  background-color: #444444;
}
.dot-legenda {
  width: 20px;
  height: 20px;
  margin: auto;
  border-radius: 10px;
  background-color: #444444;
}
.dot-atendido,
.dot-concluido {
  background-color: #1daf80;
}
.dot-agendado {
  background-color: #4e89e2;
}
.dot-pendente {
  background-color: #d9c401;
}
.dot-cancelado {
  background-color: #fe4d69;
}

.dot-sala-aberta {
  background-color: #ff7525;
}

.dot-nao-compareceu {
  background-color: #979797;
}
.dot-desistencia {
  background-color: #000000;
}
.dot-perdeu-conexao {
  background-color: #7b0327;
}
.dot-inst-conexao {
  background-color: #8b312a;
}
.style-text {
  margin-left: 5px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.8rem;
}
/* .color-style-atendido {
  color: #1daf80;
}
.color-style-agendado {
  color: #4e89e2;
}
.color-style-cancelado {
  color: #fe4d69;
} */

.color-style-atendido,
.color-style-concluido {
  color: #1daf80;
}
.color-style-agendado {
  color: #4e89e2;
}
.color-style-pendente {
  color: #d9c401;
}
.color-style-cancelado {
  color: #fe4d69;
}

.color-style-sala-aberta {
  color: #ff7525;
}

.color-style-nao-compareceu {
  color: #979797;
}
.color-style-desistencia {
  color: #000000;
}
.color-style-perdeu-conexao {
  color: #7b0327;
}
.color-style-inst-conexao {
  color: #8b312a;
}
.dot-legenda {
  font-size: 0.7rem;
  color: white;
  font-weight: bolder;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 575.98px) {
  .title-style h3 {
    text-align: center;
  }
}
</style>
