<template>
  <v-card>
    <v-toolbar style="background-color: #1daf80; color: #fff">
      <v-btn icon dark @click="$emit('close', showPixQrCode)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span class="headline"
          >Compra de Atendimento Pet: {{ infos.nome_pet }}
        </span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items> </v-toolbar-items>
    </v-toolbar>
    <Pix
      v-if="showPixQrCode"
      :txid="pix.txid"
      :pixCopiaCola="pix.pixCopiaCola"
    />
    <v-form
      v-else
      ref="form"
      class="container mt-9"
      v-model="form"
      lazy-validation
    >
      <div class="row justify-content-center align-items-end">
        <div class="col-11 col-lg-12">
          <h4 class="text-start">Informações Compra</h4>
        </div>
      </div>
      <div class="row justify-content-center align-items-end">
        <div class="col-11 col-lg-4 form-group clearfix mb-3">
          <v-select
            style="padding-top: 0 !important"
            :items="petsLista"
            label="Pet"
            :item-text="desc"
            :item-value="id"
            v-model="petSelected"
            :disabled="true"
          />
        </div>
        <!-- <div class="col-lg-4 form-group clearfix mb-3">
          <v-select
            style="padding-top: 0 !important"
            label="Loja"
            :items="lojaLista"
            :item-text="desc"
            :item-value="id"
            v-model="lojaSelected"
          />
        </div> -->
        <div class="col-11 col-lg-4 form-group clearfix mb-3">
          <v-select
            style="padding-top: 0 !important"
            label="Produtos/Serviços"
            :items="produtosLista"
            item-text="desc"
            item-value="id"
            v-model="produtosSelected"
          />
        </div>
        <div class="col-lg-4 d-none d-sm-block"></div>
      </div>
      <div class="row  justify-content-center  align-items-end">
        <div class="col-11 col-lg-12">
          <h4 class="text-start">Pagamento</h4>
        </div>
      </div>
      <div class="row justify-content-center  align-items-end">
        <div class="col-11 col-lg-4 form-group clearfix mb-3">
          <v-select
            style="padding-top: 0 !important"
            :items="tipoLista"
            label="Tipo Pagamento"
            item-text="desc"
            item-value="valor"
            v-model="tipoSelected"
          />
        </div>
        <div class="col-11 col-lg-2 form-group clearfix mb-3">
          <v-select
            v-if="tipoSelected == 'CREDIT_CARD'"
            style="padding-top: 0 !important"
            :items="parcelamentoLista"
            label="Parcelamento"
            v-model="parcelamento"
          />
        </div>
        <div class="col-lg-6 d-none d-sm-block"></div>
      </div>
      <div v-if="tipoSelected == 'CREDIT_CARD'">
        <div class="row justify-content-center  align-items-end">
          <div class="col-11 col-lg-12">
            <h4 class="text-start">Cartão</h4>
          </div>
        </div>
        <div class="row justify-content-center  align-items-end">
          <div class="col-11 col-lg-5 form-group clearfix mb-3">
            <v-text-field
              v-model="cartaoNumero"
              v-mask="'#### #### #### ####'"
              placeholder="0000 0000 0000 0000"
              :rules="cartaoNumeroRules"
              label="Número Cartão"
              style="padding-top: 0 !important"
            ></v-text-field>
          </div>
          <div class="col-11 col-lg-3 form-group clearfix mb-3">
            <v-text-field
              v-model="cartaoCode"
              v-mask="'###'"
              placeholder="000"
              :rules="cartaoCodeRules"
              label="CVV"
              style="padding-top: 0 !important"
            ></v-text-field>
          </div>
          <div class="col-11 col-lg-2 form-group clearfix mb-3">
            <v-text-field
              v-model="cartaoVencMes"
              type="text"
              v-mask="'##'"
              placeholder="00"
              :rules="cartaoVencMesRules"
              label="Mês Vencimento"
              style="padding-top: 0 !important"
            ></v-text-field>
          </div>
          <div class="col-11 col-lg-2 form-group clearfix mb-3">
            <v-text-field
              v-model="cartaoVencAno"
              v-mask="'####'"
              placeholder="0000"
              :rules="cartaoVencAnoRules"
              label="Ano  Vencimento"
              style="padding-top: 0 !important"
            ></v-text-field>
          </div>
        </div>
        <div class="row  justify-content-center align-items-end">
          <div class="col-11 col-lg-12 form-group clearfix mb-3">
            <v-text-field
              v-model="cartaoNome"
              label="Nome do Titular"
              style="padding-top: 0 !important"
            ></v-text-field>
          </div>
        </div>
      </div>
      <div class="row justify-content-center align-items-end">
        <div class="col-11 col-sm-12 layout-style text-center">
          <v-btn
            rounded
            elevation="false"
            class="bg-verde"
            :dark="!loading"
            @click="comprar"
            :disabled="loading"
          >
            <span v-if="!loading"> Comprar </span>
            <v-progress-circular
              v-else
              indeterminate
              color="white"
            ></v-progress-circular>
          </v-btn>
        </div>
      </div>
    </v-form>
  </v-card>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
// import PagSeguro from "../../services/pag_bank.js";
import PagBankService from "@/services/pag_bank.js";
import PagamentoService from "@/services/pagamento_service.js";
import PetsService from "@/services/pets_service.js";
import LojaService from "@/services/loja_service.js";
import ProdutosServicosService from "@/services/produtos_servicos_service.js";
// import VoucherService from "../../services/voucher_service";
import AuthService from "../../services/auth_service";
import Pix from "./pix.vue";

export default {
  mixins: [Mixin],
  components: { Pix },
  props: {
    infos: {
      type: Object,
      // required: true,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      // Listagens
      tipoLista: [
        { valor: "CREDIT_CARD", desc: "Cartão de Crédito" },
        { valor: "PIX", desc: "PIX" },
      ],
      lojaLista: [],
      produtosLista: [],
      petsLista: [],
      parcelamentoLista: [1],
      // Formulario
      // Dados Pagamento
      tipoSelected: "CREDIT_CARD",
      parcelamento: 1,
      // Dados Cartão
      cartaoCode: "",
      cartaoNome: "",
      cartaoNumero: "",
      cartaoVencMes: "",
      cartaoVencAno: "",
      // Informação Produto
      // petSelected: "",
      petSelected: parseInt(this.infos.id_pet),
      id_usuario: JSON.parse(sessionStorage.vuex).usuario.id,
      produtosSelected: "",
      // loja Televet,
      lojaSelected: 1,
      // Services
      services: {
        authService: AuthService.build(),
        pagamentoService: PagamentoService.build(),
        petsService: PetsService.build(),
        lojaService: LojaService.build(),
        produtosServicosService: ProdutosServicosService.build(),
      },
      pix: {
        txid: "",
        pixCopiaCola: "",
      },
      showPixQrCode: false,
    };
  },
  computed: {
    cartaoNumeroRules() {
      return [
        (v) => !!v || "Número do cartão é obrigatório",
        (v) => (v && v.length === 19) || "Número do cartão inválido",
      ];
    },
    cartaoCodeRules() {
      return [
        (v) => !!v || "Código de segurança é obrigatório",
        (v) =>
          (v && (v.length === 3 || v.length === 4)) ||
          "Código de segurança inválido",
      ];
    },
    cartaoVencMesRules() {
      return [
        (v) => !!v || "Mês de vencimento é obrigatório",
        (v) =>
          (v && parseInt(v) >= 1 && parseInt(v) <= 12) ||
          "Mês de vencimento inválido",
      ];
    },
    cartaoVencAnoRules() {
      return [
        (v) => !!v || "Ano de vencimento é obrigatório",
        (v) =>
          (v && parseInt(v) >= 1900 && parseInt(v) <= 2999) ||
          "Ano de vencimento inválido",
      ];
    },
  },
  watch: {},
  mounted() {
    console.log(this.infos);
    this.getListaPets();
    // this.getListaLoja();
    this.getListaProdutos();
  },
  methods: {
    async getListaPets() {
      await this.services.petsService
        .getPetsByCliente()
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          const { pets } = data;
          this.petsLista = pets.map((pet) => {
            return {
              text: pet.nome_pet,
              value: parseInt(pet.id_pet),
            };
          });
        });
    },
    async getListaLoja() {
      this.lojaLista = [];
      await this.services.lojaService
        .getLojaByUnidadeId(24)
        .then(async (response) => {
          const { data } = await response.json();
          const { produtos } = data;
          const totalList = produtos.length;
          for (let index = 0; index < totalList; index++) {
            const element = produtos[index];
            const quantidadeProd = 1;
            this.lojaLista.push({
              id: element.id,
              valor: {
                id_loja_produto: element.id,
                quantidade: quantidadeProd,
                valor_unidade: parseFloat(element.valor),
                valor: quantidadeProd * parseFloat(element.valor),
              },
              desc: `${element.nome} - ${parseFloat(
                element.valor
              ).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })}`,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getListaProdutos() {
      this.produtosLista = [];
      await this.services.produtosServicosService
        .getProdutosServicosbyLojaAtiva(1, 1)
        .then(async (response) => {
          const { data } = await response.json();
          const { produtos } = data;
          const totalList = produtos.length;
          for (let index = 0; index < totalList; index++) {
            const element = produtos[index];
            const quantidadeProd = 1;
            this.produtosLista.push({
              id: element.id,
              valor: {
                id_loja_produto: parseInt(element.id),
                quantidade: quantidadeProd,
                valor_unidade: parseFloat(element.valor),
                valor: quantidadeProd * parseFloat(element.valor),
              },
              desc: `${element.nome} - ${parseFloat(
                element.valor
              ).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })}`,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    comprar() {
      this.loading = true;
      // this.services.pagamentoService.getPublicKey().then(async (response) => {
      //   const { data } = await response.json();
      //   this.encriptografarCard(data.public_key);
      // });
      this.postPagamento();
    },
    async encriptografarCard(public_key) {
      try {
        await PagBankService.loadSDK();
        const cardData = {
          holder: this.cartaoNome,
          publicKey: public_key,
          number: this.cartaoNumero.replaceAll(" ", ""),
          expMonth: this.cartaoVencMes,
          expYear: this.cartaoVencAno,
          securityCode: this.cartaoCode,
        };
        const encryptedData = PagBankService.encryptCardData(cardData);
        if (!encryptedData.hasErrors) {
          this.postPagamento(encryptedData.encryptedCard);
        } else {
          this.$_ACTIONS_showSnackbarMessage({
            message: "Confirme os dados do cartão",
            color: "error",
          });
        }
        // console.log("Dados Encriptados:", encryptedData);
      } catch (error) {
        console.error("Erro ao carregar o SDK do PagBank:", error);
      }
    },
    postPagamento() {
      if (this.validarForm()) {
        if (this.tipoSelected == "PIX") {
          const modelo = this.criarModeloPagamentoPix();
          this.services.pagamentoService.postPagamentoPix(
            {
              onSucess: (status) => (body) => {
                if (status === 200) {
                  this.$_ACTIONS_showSnackbarMessage({
                    message:
                      body.message || this.$global.messages.internalServerError,
                    color: "sucess",
                  });
                  if (body) {
                    const dados_compra = body.data;

                    this.pix.txid = dados_compra.txid;
                    this.pix.pixCopiaCola = dados_compra.pix_copia_cola;

                    this.showPixQrCode = true;

                    this.loading = false;
                  }
                  console.log(body.data);
                } else {
                  this.$_ACTIONS_showSnackbarMessage({
                    message:
                      body.message || this.$global.messages.internalServerError,
                    color: "error",
                  });
                  this.loading = false;
                }
              },
              onError: (message) => {
                this.$_ACTIONS_showSnackbarMessage({
                  message: message || this.$global.messages.internalServerError,
                  color: "error",
                });
                this.loading = false;
              },
              onEnd: () => {},
            },
            modelo
          );
        } else {
          const modelo = this.criarModelo();
          this.services.pagamentoService.postPagamento(
            {
              onSucess: (status) => (body) => {
                if (status === 200) {
                  this.$_ACTIONS_showSnackbarMessage({
                    message:
                      body.message || this.$global.messages.internalServerError,
                    color: "sucess",
                  });
                  if (body.data) {
                    const resposta = {
                      id_compra: body.data[0].id_compra,
                      voucher: {
                        id: body.data[1].vouchers[0].id_voucher,
                        serial: body.data[1].vouchers[0].serial,
                      },
                    };
                    this.$emit("pago", resposta);
                    this.loading = false;
                  }
                  console.log(body.data);
                } else {
                  this.$_ACTIONS_showSnackbarMessage({
                    message:
                      body.message || this.$global.messages.internalServerError,
                    color: "error",
                  });
                  this.loading = false;
                }
              },
              onError: (message) => {
                this.$_ACTIONS_showSnackbarMessage({
                  message: message || this.$global.messages.internalServerError,
                  color: "error",
                });
                this.loading = false;
              },
              onEnd: () => {},
            },
            modelo
          );
        }
      } else {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Formulário Invalido",
          color: "error",
        });
        this.loading = false;
      }
    },
    criarModelo() {
      let valorTotal = 0;
      let listaProdutos = [];
      for (let index = 0; index < this.produtosSelected.length; index++) {
        const index = this.produtosLista.findIndex(
          (find) => this.produtosSelected[index] == find.id
        );
        const element = this.produtosLista[index].valor;
        valorTotal = valorTotal + parseFloat(element.valor);
        listaProdutos.push(element);
        // listaProdutos.push({
        //   id_loja_produto: element.id_loja_produto,
        //   quantidade: parseInt(element.quantidade),
        //   valor_unidade: parseInt(element.unidade),
        //   valor: parseInt(element.valor),
        // });
      }
      const modelo = {
        id_usuario: this.id_usuario,
        id_pet: this.petSelected,
        id_loja: this.lojaSelected,
        produtos: listaProdutos,
        valor_total: valorTotal,
        payment_method: {
          type: this.tipoSelected,
          installments: this.parcelamento,
          capture: true,
          card: {
            num_card: this.cartaoNumero.replaceAll(" ", ""),
            security_code: this.cartaoCode,
            holder: {
              name: this.cartaoNome,
            },
            exp_month: this.cartaoVencMes,
            exp_year: this.cartaoVencAno,
            store: false,
          },
        },
      };
      return modelo;
    },
    criarModeloPagamentoPix() {
      let valorTotal = 0;
      let listaProdutos = [];
      for (let index = 0; index < this.produtosSelected.length; index++) {
        const index = this.produtosLista.findIndex(
          (find) => this.produtosSelected[index] == find.id
        );
        const element = this.produtosLista[index].valor;
        valorTotal = valorTotal + parseFloat(element.valor);
        listaProdutos.push(element);
      }

      const modelo = {
        id_agendamento: this.infos.id_agendamento,
        id_usuario: this.id_usuario,
        id_pet: this.petSelected,
        id_loja: this.lojaSelected,
        produtos: listaProdutos,
        valor_total: valorTotal,
      };
      return modelo;
    },
    validarForm() {
      if (
        this.petSelected &&
        this.lojaSelected &&
        this.tipoSelected &&
        this.parcelamento &&
        this.cartaoNome &&
        this.produtosSelected
      ) {
        return true;
      } else {
        return true;
        // return false;
      }
      // return true;
    },
  },
};
</script>

<style scoped>
.form-group label {
  text-align: left !important;
  display: block;
}

.form-group .label-genero {
  margin-right: 1rem;
}
.layout-style {
  display: flex;
  justify-content: end;
}
@media (max-width: 575.98px) {
  .btn-app-primary {
    width: 100%;
  }
  .layout-style {
    justify-content: center;
  }
}
</style>
