import HTTPService from "@/services/http_service.js";

export default class MedicinaCarinhoService extends HTTPService {
  resource = "pet/cartoavouchers";

  static build() {
    return new MedicinaCarinhoService();
  }
  /**
        @returns {Promise<Response>}
     */

  // verificar número cartão com
  getCheckCartaoVoucher(idPet) {
    return this.get(`${this.resource}/${idPet}`);
  }

  // Cadastar número cartão medicina com carinho
  postCadastrarCartao(responseFunctions, model) {
    this.jsonRequest(
      responseFunctions,
      "POST",
      `medicina-carinho/valida`,
      model
    );
  }
  // Cadastar número cartão medicina com carinho
  postValidarCartao(responseFunctions, model) {
    this.jsonRequest(
      responseFunctions,
      "POST",
      `medicina-carinho/valida`,
      model
    );
  }
  // Cadastar número cartão medicina com carinho
  postValidarMCC(responseFunctions, model) {
    this.jsonRequest(
      responseFunctions,
      "POST",
      `loja/compra/medicinacarinho/new`,
      model
    );
  }
}
