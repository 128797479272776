import HTTPService from "@/services/http_service.js";

export default class ConfirmarAgendamentoService extends HTTPService {
  resource = "pet/cartoavouchers";

  static build() {
    return new ConfirmarAgendamentoService();
  }
  /**
        @returns {Promise<Response>}
     */

  // Confirmar consulta medicina com carinho
  postConfirmarConsultaMCC(responseFunctions, model) {
    /*
      - id_usuario
      - id_pet
      - id_loja
      - id_agendamento
    */
    this.jsonRequest(
      responseFunctions,
      "POST",
      `loja/compra/medicinacarinho/new`,
      model
    );
  }
  // voucher no agendamento
  postAgendamentoVoucher(responseFunctions, model) {
    // - id_agendamento
    // - id_voucher
    this.jsonRequest(
      responseFunctions,
      "POST",
      `atendimento/voucher/vinculacao`,
      model
    );
  }
}
