<template>
  <div>
    <div class="p-3 mt-8">
      <div class="row">
        <div class="col-md-8 m-auto">
          <div class="row justify-content-between">
            <h3 class="mt-0 mb-2 font-weight-bold text-left">
              Cadastro de Cliente
            </h3>
            <button
              @click="voltar()"
              class="btn btn-app-primary font-weight-bold text-left"
            >
              Voltar para login
            </button>
          </div>
        </div>
      </div>
    </div>

    <form class="p-lg-3 row">
      <div class="col-md-8 m-auto">
        <div class="text-center mb-4">
          <!-- <a href="index.html"> -->
          <span>
            <img
              src="@/assets/images/logo_desk_vet_crooped.png"
              alt=""
              height="60px"
            />
            <!-- <img src="@/assets/images/logo-login.png" alt="" height="120px" /> -->
          </span>
          <!-- </a> -->
        </div>
        <v-stepper v-model="e1">
          <v-stepper-header>
            <v-stepper-step
              :complete="e1 > 1"
              editable
              color="rgb(29, 175, 128)"
              step="1"
              :rules="rules1 ? [() => true] : [() => false]"
            >
              Passo 1
              <small v-if="!rules1" class="mt-2">Alerta de error</small>
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              :complete="e1 > 2"
              editable
              color="rgb(29, 175, 128)"
              step="2"
              :rules="rules2 ? [() => true] : [() => false]"
            >
              Passo 2
              <small v-if="!rules2" class="mt-2">Alerta de error</small>
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              :complete="e1 > 3"
              editable
              color="rgb(29, 175, 128)"
              step="3"
              :rules="rules3 ? [() => true] : [() => false]"
            >
              Passo 3
              <small v-if="!rules3" class="mt-2">Alerta de error</small>
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              :complete="e1 > 4"
              editable
              color="rgb(29, 175, 128)"
              step="4"
            >
              Passo 4
              <!-- <small v-if="!rules4" class="mt-2">Alerta de error</small> -->
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
              :complete="e1 > 5"
              editable
              color="rgb(29, 175, 128)"
              step="5"
            >
              Passo 5
              <!-- <small v-if="!rules5" class="mt-2">Alerta de error</small> -->
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card class="mb-12" elevation="0">
                <div class="row">
                  <div class="col-lg-6 form-group clearfix mb-3">
                    <label for="nome">Nome Completo</label>
                    <v-text-field
                      :error-messages="error.nome"
                      v-model="nome"
                      :rules="[rules.required]"
                      :type="'text'"
                      placeholder="Seu nome completo"
                      style="padding-top: 0 !important"
                    ></v-text-field>
                  </div>

                  <div class="col-lg-6 form-group clearfix mb-3">
                    <label for="emailCadastroUsuario">Email</label>
                    <v-text-field
                      :error-messages="error.email"
                      v-model="email"
                      :rules="[rules.required, rules.email]"
                      :type="'email'"
                      placeholder="Escreva seu email"
                      style="padding-top: 0 !important"
                    ></v-text-field>
                  </div>

                  <div class="col-lg-4 form-group clearfix mb-3">
                    <label for="Senha">Senha</label>
                    <v-text-field
                      :error-messages="error.senha"
                      v-model="senha"
                      background-color="grey lighten-2"
                      :type="'password'"
                      style="padding-top: 0 !important"
                    ></v-text-field>
                  </div>

                  <div class="col-lg-4 form-group clearfix mb-3">
                    <label for="Telefone01">Telefone Celular:</label>
                    <v-text-field
                      v-mask="'(##)#####-####'"
                      :error-messages="error.telefone_1"
                      v-model="telefone_1"
                      :type="'text'"
                      placeholder="(99) 99999-9999"
                      style="padding-top: 0 !important"
                    ></v-text-field>
                  </div>

                  <div class="col-lg-4 form-group clearfix mb-3">
                    <label for="Telefone01">Telefone Fixo:</label>
                    <v-text-field
                      v-mask="'(##)####-####'"
                      :error-messages="error.telefone_2"
                      v-model="telefone_2"
                      :type="'text'"
                      placeholder="(99) 99999-9999"
                      style="padding-top: 0 !important"
                    ></v-text-field>
                  </div>
                </div>
              </v-card>

              <v-btn
                class="font-weight-bold"
                color="rgb(29, 175, 128)"
                style="color: #fff !important; text-transform: none"
                @click="passaEtapa(2)"
              >
                Próximo
              </v-btn>
            </v-stepper-content>
            <v-stepper-content step="2">
              <v-card class="mb-12" elevation="0">
                <div class="row">
                  <div class="col-lg-3 form-group clearfix mb-3">
                    <label for="CPF">CPF</label>
                    <v-text-field
                      v-mask="'###.###.###-##'"
                      :error-messages="error.cpf"
                      v-model="cpf"
                      :type="'text'"
                      placeholder="000.000.000-00"
                      style="padding-top: 0 !important"
                    ></v-text-field>
                    <span class="text-muted">ex: "123.456.7890-12"</span>
                  </div>
                  <div class="col-lg-3 form-group clearfix mb-3">
                    <label for="RG">RG</label>

                    <v-text-field
                      :error-messages="error.rg"
                      v-model="rg"
                      :type="'text'"
                      placeholder="00.000.000-0"
                      style="padding-top: 0 !important"
                    ></v-text-field>

                    <span class="text-muted">ex: "xx.xxx.xxx-x"</span>
                  </div>
                  <div class="col-lg-3 form-group clearfix mb-3">
                    <label>Data de nascimento</label>

                    <v-text-field
                      :error-messages="error.data_nascimento"
                      v-model="data_nascimento"
                      clear-icon="clear"
                      style="margin: 0 5px; padding-top: 5px !important"
                      :type="'date'"
                      :outlined="false"
                    />

                    <span class="text-muted">ex: "Dia/Mês/Ano"</span>
                  </div>
                  <div class="col-lg-3 form-group clearfix mb-3">
                    <label class="">Gênero</label>
                    <v-radio-group
                      :error-messages="error.genero"
                      v-model="genero"
                    >
                      <v-radio label="Masculino" value="M" />
                      <v-radio label="Feminino" value="F" />
                    </v-radio-group>
                  </div>
                  <div class="col-lg-3 form-group clearfix mb-3 estado-civil">
                    <label for="Estado-civil">Estado civil</label>

                    <v-text-field
                      :error-messages="error.estado_civil"
                      v-model="estadoCivil"
                      clear-icon="clear"
                      style="margin: 0 5px; padding-top: 5px !important"
                      :type="'text'"
                      :outlined="false"
                    />

                    <span class="text-muted"
                      >ex: Casado, Solteiro, Divorciado, Viúvo"</span
                    >
                  </div>
                  <div class="col-lg-3 form-group clearfix mb-3 estado-civil">
                    <label for="Estado-civil">Profissão</label>

                    <v-text-field
                      :error-messages="error.profissao"
                      v-model="profissao"
                      clear-icon="clear"
                      style="margin: 0 5px; padding-top: 5px !important"
                      :type="'text'"
                      :outlined="false"
                    />
                  </div>
                  <div class="col-lg-3 form-group clearfix mb-3 estado-civil">
                    <label for="Estado-civil">Indicado por</label>

                    <v-text-field
                      :error-messages="error.indicado_por"
                      v-model="indicado_por"
                      clear-icon="clear"
                      style="margin: 0 5px; padding-top: 5px !important"
                      :type="'text'"
                      :outlined="false"
                    />
                  </div>
                  <div class="col-lg-3 form-group clearfix mb-3">
                    <label for="Convenio">Convênio</label>

                    <v-text-field
                      v-model="convenio"
                      :type="'text'"
                      style="margin: 0 5px; padding-top: 5px !important"
                    ></v-text-field>
                  </div>
                </div>
              </v-card>

              <v-btn
                class="mx-2 font-weight-bold"
                color="grey darken-1"
                @click="passaEtapa(1)"
                style="color: #fff !important; text-transform: none"
              >
                Anterior
              </v-btn>

              <v-btn
                class="mx-2 font-weight-bold"
                color="rgb(29, 175, 128)"
                style="color: #fff !important; text-transform: none"
                @click="passaEtapa(3)"
              >
                Próximo
              </v-btn>
            </v-stepper-content>
            <v-stepper-content step="3">
              <v-card class="mb-12" elevation="0">
                <div class="row">
                  <div class="col-lg-3 form-group clearfix mb-3">
                    <label for="CEP">CEP</label>

                    <v-text-field
                      v-mask="'#####-###'"
                      :error-messages="error.cep"
                      v-model="cep"
                      @blur="searchCep"
                      :type="'text'"
                      placeholder="00000-000"
                      style="padding-top: 0 !important"
                    ></v-text-field>

                    <span class="text-muted">ex: "xxxxx-xxx"</span>
                  </div>
                  <div class="col-lg-3 form-group clearfix mb-3">
                    <label for="Rua">Logradouro</label>

                    <v-text-field
                      :error-messages="error.logradouro"
                      v-model="logradouro"
                      background-color="grey lighten-2"
                      :type="'text'"
                      style="padding-top: 0 !important"
                    ></v-text-field>
                  </div>
                  <div class="col-lg-3 form-group clearfix mb-3">
                    <label for="Bairro">Bairro</label>

                    <v-text-field
                      :error-messages="error.bairro"
                      v-model="bairro"
                      background-color="grey lighten-2"
                      :type="'text'"
                      style="padding-top: 0 !important"
                    ></v-text-field>
                  </div>
                  <div class="col-lg-3 form-group clearfix mb-3">
                    <label for="Numero">Numero</label>

                    <v-text-field
                      :error-messages="error.numero"
                      v-model="numero"
                      :type="'text'"
                      style="padding-top: 0 !important"
                    ></v-text-field>
                  </div>
                  <div class="col-lg-3 form-group clearfix mb-3">
                    <label for="Complemento">Complemento</label>

                    <v-text-field
                      :error-messages="error.complemento"
                      v-model="complemento"
                      :type="'text'"
                      style="padding-top: 0 !important"
                    ></v-text-field>
                  </div>
                  <div class="col-lg-3 form-group clearfix mb-3">
                    <label for="Referencia">Referência</label>

                    <v-text-field
                      :error-messages="error.referencias"
                      v-model="referencias"
                      :type="'text'"
                      style="padding-top: 0 !important"
                    ></v-text-field>
                  </div>
                  <div class="col-lg-3 form-group clearfix mb-3">
                    <label for="Cidade">Cidade</label>

                    <v-text-field
                      :error-messages="error.cidade"
                      v-model="cidade"
                      background-color="grey lighten-2"
                      :type="'text'"
                      style="padding-top: 0 !important"
                    ></v-text-field>
                  </div>
                  <div class="col-lg-3 form-group clearfix mb-3">
                    <label for="Estado">Estado</label>
                    <v-text-field
                      :error-messages="error.estado"
                      v-model="estado"
                      background-color="grey lighten-2"
                      :type="'text'"
                      style="padding-top: 0 !important"
                    ></v-text-field>
                  </div>
                </div>
              </v-card>

              <v-btn
                class="mx-2 font-weight-bold"
                color="grey darken-1"
                @click="passaEtapa(2)"
                style="color: #fff !important; text-transform: none"
              >
                Anterior
              </v-btn>
              <v-btn
                class="mx-2 font-weight-bold"
                color="rgb(29, 175, 128)"
                style="color: #fff !important; text-transform: none"
                @click="passaEtapa(4)"
              >
                Próximo
              </v-btn>
              <!-- <button
                @click.prevent="cadastrarPaciente"
                class="btn btn-app-primary mx-2 font-weight-bold"
              >
                <span v-if="!loading"> Cadastrar </span>
                <v-progress-circular
                  v-else
                  indeterminate
                  color="white"
                ></v-progress-circular>
              </button> -->
            </v-stepper-content>
            <v-stepper-content step="4">
              <div>
                <h3>Termos de Uso</h3>
                <v-card color="basil" flat>
                  <v-card
                    class="my-6 overflow-termos"
                    elevation="0"
                    id="scroll-target"
                  >
                    <termos-responsabilidade />
                  </v-card>
                  <v-checkbox
                    v-model="termosCheck"
                    label="Li e aceito os termos acima."
                    hide-details
                  ></v-checkbox>
                  <!-- <v-container>
                    <v-checkbox class=" font-weight-bold" v-model="termosCheck">
                      <template v-slot:label>
                        <div class="check-style">
                          Li e aceito os termos acima.
                        </div>
                      </template>
                    </v-checkbox>
                  </v-container> -->
                </v-card>
                <div class="mt-6">
                  <v-btn
                    class="mx-2 font-weight-bold"
                    color="grey darken-1"
                    @click="passaEtapa(3)"
                    style="color: #fff !important; text-transform: none"
                  >
                    Anterior
                  </v-btn>
                  <v-btn
                    class="mx-2 font-weight-bold"
                    color="rgb(29, 175, 128)"
                    style="color: #fff !important; text-transform: none"
                    @click="e1 = 5"
                    :disabled="!termosCheck"
                  >
                    Próximo
                  </v-btn>
                </div>
              </div>
            </v-stepper-content>
            <v-stepper-content step="5">
              <div>
                <h3>Contrato Desk Vet</h3>
                <v-card color="basil" flat>
                  <v-card
                    class="my-6 overflow-termos"
                    elevation="0"
                    id="scroll-target"
                  >
                    <contrato-televet />
                  </v-card>
                  <v-container>
                    <v-checkbox
                      v-model="contratoCheck"
                      label="Li e aceito os termos acima."
                      hide-details
                    ></v-checkbox>
                    <!-- 
                    <v-checkbox
                      class=" font-weight-bold"
                      v-model="contratoCheck"
                    >
                      <template v-slot:label>
                        <div class="check-style">
                          Li e aceito os termos acima.
                        </div>
                      </template>
                    </v-checkbox> -->
                  </v-container>
                </v-card>
                <div class="mt-6">
                  <v-btn
                    class="mx-2 font-weight-bold"
                    color="grey darken-1"
                    @click="e1 = 4"
                    style="color: #fff !important; text-transform: none"
                  >
                    Anterior
                  </v-btn>
                  <button
                    @click.prevent="cadastrarPaciente"
                    class="btn btn-app-primary mx-2 font-weight-bold"
                    :disabled="!termosCheck || !contratoCheck"
                  >
                    <span v-if="!loading"> Cadastrar </span>
                    <v-progress-circular
                      v-else
                      indeterminate
                      color="white"
                    ></v-progress-circular>
                  </button>
                </div>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </div>
    </form>

    <v-snackbar
      v-if="messageSnackbar"
      v-model="messageSnackbar"
      :color="typeMessageSnackbar"
      :timeout="2000"
    >
      {{ messageSnackbar }}
      <template v-slot:action="{ attrs }">
        <v-btn text :color="'white'" v-bind="attrs" @click="fecharSnackbar">
          Ok
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import PacienteService from "@/services/paciente_service.js";
import CEPMixin from "@/mixins/cep_mixin.js";
import SnackbarMixin from "@/mixins/vuex_snackbar_mixin.js";
import TermosResponsabilidade from "@/components/dados_cadastrais/termos_responsabilidade.vue";
import ContratoTelevet from "@/components/dados_cadastrais/contrato_televet_play.vue";

export default {
  mixins: [CEPMixin, SnackbarMixin],
  components: {
    TermosResponsabilidade,
    ContratoTelevet,
  },
  data() {
    return {
      //Inicio Stepers
      e1: 1,
      //Fim Stepers

      typeMessageSnackbar: "",
      //Validação de Senha
      show1: false,
      show2: false,
      loading: false,
      password: "Password",
      // Formulário
      formCadastro: "",
      nome: "",
      email: "",
      telefone_1: "",
      telefone_2: "",
      cpf: "",
      rg: "",
      data_nascimento: "",
      genero: "",
      estadoCivil: "",
      indicado_por: "",
      profissao: "",
      messageSnackbar: "",
      cep: "",
      logradouro: "",
      bairro: "",
      numero: "",
      complemento: "",
      referencias: "",
      cidade: "",
      estado: "",
      convenio: "",
      senha: "",
      error: {
        nome: "",
        email: "",
        telefone_1: "",
        telefone_2: "",
        cpf: "",
        rg: "",
        data_nascimento: "",
        genero: "",
        estadoCivil: "",
        indicado_por: "",
        profissao: "",
        cep: "",
        logradouro: "",
        bairro: "",
        numero: "",
        complemento: "",
        referencias: "",
        cidade: "",
        estado: "",
        senha: "",
        statusResposta: null,
      },
      rules: {
        required: (value) => !!value || "Campo obrigatório.",
        min: (v) => v.length >= 8 || "Minimo de 8 caracteres",
        emailMatch: () => `O e-mail e a senha inseridos não correspondem`,
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Email inválido.";
        },
      },
      rules1: true,
      rules2: true,
      rules3: true,
      rules4: true,
      rules5: true,
      termosCheck: false,
      contratoCheck: false,
      termosValidos: true,
      contratoValidos: true,
      tab: null,
    };
  },
  mounted() {
    this.formCadastro = document.forms[0];
  },
  methods: {
    onScrollTermos(e) {
      if (e.target.scrollTop == e.target.scrollTopMax) {
        this.termosValidos = true;
      } else {
        this.termosCheck = false;
        this.termosValidos = false;
      }
    },
    onScrollContrato(e) {
      if (e.target.scrollTop == e.target.scrollTopMax) {
        this.contratoValidos = true;
      } else {
        this.contratoCheck = false;
        this.contratoValidos = false;
      }
    },
    searchCep() {
      this.$buscarCep(this, this.cep);
    },
    passaEtapa(etapa) {
      if (etapa > this.e1) {
        const avancar = this.avancarEtapa(etapa - 1);
        if (avancar) {
          this.e1 = etapa;
        }
      } else {
        this.e1 = etapa;
      }
    },
    voltar() {
      this.$router.replace("login");
    },
    fecharSnackbar() {
      this.messageSnackbar = "";
      this.zerarErros();
    },
    zerarFormulario() {
      Array.from(this.formCadastro.elements).forEach((element) => {
        element.value = "";
      });
    },
    zerarErros() {
      Object.keys(this.error).forEach((error) => {
        this.error[error] = "";
      });
    },
    cadastrarPaciente() {
      if (!this.formCadastro.checkValidity()) {
        return;
      }
      const paciente = {
        nome: this.nome,
        email: this.email,
        senha: this.senha,
        telefone_1: this.telefone_1,
        telefone_2: this.telefone_2,
        cpf: this.cpf,
        rg: this.rg,
        data_nascimento: this.data_nascimento,
        genero: this.genero,
        estado_civil: this.estadoCivil,
        profissao: this.profissao,
        indicado_por: this.indicado_por,
        observacoes: this.observacoes,
        cep: this.cep,
        logradouro: this.logradouro,
        bairro: this.bairro,
        numero: this.numero,
        complemento: this.complemento,
        referencias: this.referencias,
        cidade: this.cidade,
        estado: this.estado,
        convenio: this.convenio,
      };
      this.loading = true;
      const responseFunctions = {
        onSucess: this.mostrarFeedback(),
        onError: (error) => {
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
          console.log(error);
        },
        onEnd: () => {
          this.loading = false;
        },
      };
      const pacienteService = new PacienteService();
      pacienteService.self_register(responseFunctions, paciente);
    },

    isValidCPF(cpf) {
      if (typeof cpf !== "string") return false;
      cpf = cpf.replace(/[\s.-]*/gim, "");
      if (
        !cpf ||
        cpf.length != 11 ||
        cpf == "00000000000" ||
        cpf == "11111111111" ||
        cpf == "22222222222" ||
        cpf == "33333333333" ||
        cpf == "44444444444" ||
        cpf == "55555555555" ||
        cpf == "66666666666" ||
        cpf == "77777777777" ||
        cpf == "88888888888" ||
        cpf == "99999999999"
      ) {
        return false;
      }
      var soma = 0;
      var resto;
      for (let i = 1; i <= 9; i++) {
        soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
      }

      resto = (soma * 10) % 11;

      if (resto == 10 || resto == 11) {
        resto = 0;
      }

      if (resto != parseInt(cpf.substring(9, 10))) {
        return false;
      }

      soma = 0;
      for (var i = 1; i <= 10; i++) {
        soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
      }

      resto = (soma * 10) % 11;
      if (resto == 10 || resto == 11) {
        resto = 0;
      }

      if (resto != parseInt(cpf.substring(10, 11))) {
        return false;
      }

      return true;
    },

    avancarEtapa(etapa) {
      this.zerarErros();

      const rulesVerifica = {
        cpf: { validation: this.isValidCPF, error: "cpf inválido" },
        senha: {
          validation: function(e) {
            return e.length >= 6;
          },
          error: "a senha precisa ter 6 ou mais caracteres",
        },
      };

      const feedbackEtapa = [
        ["nome", "email", "senha", "telefone_1"],
        ["cpf", "data_nascimento", "genero"],
        ["cep", "logradouro", "bairro", "numero", "cidade", "estado"],
      ];

      const etapaAtual = feedbackEtapa[etapa - 1];
      let temErro = false;

      for (let i = 0; i < etapaAtual.length; i++) {
        const campoAtual = etapaAtual[i];
        if (rulesVerifica[campoAtual]) {
          const rule = rulesVerifica[campoAtual];

          if (!rule.validation(this[campoAtual])) {
            this.error[campoAtual] = rule.error;
            temErro = true;
          }
        }

        if (!this[campoAtual]) {
          this.error[campoAtual] = "Esse campo é obrigatório";
          temErro = true;
        }
      }

      if (temErro) {
        switch (this.e1) {
          case 1:
            this.rules1;
            break;
          case 2:
            this.rules2;
            break;
          case 3:
            this.rules3;
            break;
          case 4:
            this.rules4;
            break;
          case 5:
            this.rules5;
            break;
        }
      }

      return !temErro;
    },

    mostrarFeedback() {
      return (status) => (body) => {
        this.zerarErros();
        if (body.message) {
          if (status === 200) {
            this.$_ACTIONS_showSnackbarMessage({
              message: body.message,
              color: "sucess",
            });
            this.zerarFormulario();
            this.$router.replace("login");
            // this.$router.replace("cadastrar-primeiro-pet");
          }
          if (status === 400 && body.errors) {
            const errors = body.errors;
            Object.keys(body.errors).forEach((error) => {
              if (errors[error]) {
                this.error[error] = errors[error];
              }
              let keys = Object.keys(errors);
              this.rules1 = true;
              this.rules2 = true;
              this.rules3 = true;
              this.rules4 = true;
              this.rules5 = true;
              if (
                keys.includes("nome") ||
                keys.includes("email") ||
                keys.includes("senha") ||
                keys.includes("telefone_1")
              ) {
                this.e1 = 1;
                this.rules1 = false;
              }
              if (
                keys.includes("cpf") ||
                keys.includes("rg") ||
                keys.includes("data_nascimento") ||
                keys.includes("genero") ||
                keys.includes("profissão") ||
                keys.includes("estado_civil")
              ) {
                this.e1 = this.e1 >= 2 ? 2 : 1;
                this.rules2 = false;
              }
              if (keys.includes("cep") || keys.includes("numero")) {
                this.e1 = this.e1 < 3 ? this.e1 : 3;
                this.rules3 = false;
              }
            });
          }
          if (status === 500) {
            this.$_ACTIONS_showSnackbarMessage({
              message: this.$global.messages.internalServerError,
              color: "warning",
            });
          }
        } else {
          this.$_ACTIONS_showSnackbarMessage({
            message: "Erro no processamento de sua solicitação.",
            color: "error",
          });
        }
      };
    },
  },
};
</script>

<style scoped>
.form-group label {
  text-align: left !important;
  display: block;
}
.form-group .label-genero {
  margin-right: 1rem;
}
.overflow-termos {
  max-height: 380px;
  overflow: auto;
}
.v-label {
  display: contents !important;
}
.text-disable {
  color: gray;
}
.tab-ativa {
  background-color: rgb(29, 175, 128);
}
@media (max-width: 576px) {
  .overflow-termos {
    max-height: 350px;
    overflow: auto;
  }
}
</style>
